export function getCurrentDate() {
  const dateObj = new Date();
  const currentDay = new Date().getDate();
  const currentMonth = dateObj.getMonth() + 1;
  const currentYear = dateObj.getFullYear();
  return currentMonth + "-" + currentDay + "-" + currentYear;
}

export function sleep(ms = 0) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
