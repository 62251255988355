import Container from "react-bootstrap/Container";
import { Form, Col, Row, Button } from "react-bootstrap";
import { tailChase } from "ldrs";
import GridAdjuster from "./GridAdjuster";

function SandboxForm(props) {
  tailChase.register();

  return (
    <Container>
      <Col>
        <Row>
          <Form className="sandbox-form">
            <Form.Select
              name="assetType"
              size="sm"
              aria-label="Default select example"
              style={{ marginTop: 0, marginBottom: 0, fontSize: 14 }}
              onChange={(e) =>
                props.handleFormChange(e.target.name, e.target.value)
              }
            >
              <option>Select Asset Type</option>
              <option value="company_logo">Company Logo</option>
              {/* <option value="company_logos_from_file">
                Company Logos From File
              </option> */}
              {/* <option value="bar_chart">Bar Chart</option> */}
              <option value="executive_headshot">Executive Headshot</option>
              <option value="icon">Icon</option>
            </Form.Select>

            {/* {props.formData.assetType === "company_logo" && (
              <Form.Group controlId="formBasicText1">
                <Form.Label
                  style={{ marginBottom: 0, marginTop: 10 }}
                  className="demo-form-input-label"
                >
                  Company Website Url
                </Form.Label>
                <Form.Control
                  name="companyUrl"
                  size="sm"
                  type="text"
                  onChange={(e) =>
                    props.handleFormChange(e.target.name, e.target.value)
                  }
                />
              </Form.Group>
            )} */}

            {props.formData.assetType === "executive_headshot" && (
              <Form.Group controlId="formBasicText1">
                <Form.Label
                  style={{ marginBottom: 0, marginTop: 10 }}
                  className="demo-form-input-label"
                >
                  Executive Name
                </Form.Label>
                <Form.Control
                  name="executiveName"
                  size="sm"
                  type="text"
                  onChange={(e) =>
                    props.handleFormChange(e.target.name, e.target.value)
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent default action of Enter key
                    }
                  }}
                />
              </Form.Group>
            )}

            {(props.formData.assetType === "company_logo" ||
              props.formData.assetType === "executive_headshot") && (
              <Form.Group controlId="formBasicText1">
                <Form.Label
                  style={{ marginBottom: 0, marginTop: 10 }}
                  className="demo-form-input-label"
                >
                  Company Name
                </Form.Label>
                <Form.Control
                  name="companyName"
                  size="sm"
                  type="text"
                  onChange={(e) =>
                    props.handleFormChange(e.target.name, e.target.value)
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent default action of Enter key
                    }
                  }}
                />
              </Form.Group>
            )}

            {props.formData.assetType === "company_logo" && (
              <Form.Group controlId="formBasicText1">
                <Form.Label
                  style={{ marginBottom: 0, marginTop: 10 }}
                  className="demo-form-input-label"
                >
                  Website Url
                </Form.Label>
                <Form.Label style={{ marginTop: 0 }} className="form_body">
                  Optional field. Exclude http and www.
                </Form.Label>
                <Form.Control
                  name="websiteUrl"
                  size="sm"
                  type="text"
                  placeholder="example.com"
                  onChange={(e) =>
                    props.handleFormChange(e.target.name, e.target.value)
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent default action of Enter key
                    }
                  }}
                />
              </Form.Group>
            )}

            {props.formData.assetType === "icon" && (
              <Form.Group controlId="formBasicText1">
                <Form.Label
                  style={{ marginBottom: 0, marginTop: 10 }}
                  className="demo-form-input-label"
                >
                  Icon Name
                </Form.Label>
                <Form.Control
                  name="iconName"
                  size="sm"
                  type="text"
                  onChange={(e) =>
                    props.handleFormChange(e.target.name, e.target.value)
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent default action of Enter key
                    }
                  }}
                />
                <Form.Label
                  style={{ marginBottom: 0, marginTop: 10 }}
                  className="demo-form-input-label"
                >
                  Icon Color
                </Form.Label>
                <Form.Control
                  name="iconHexCode"
                  type="color"
                  defaultValue={"#000000"}
                  style={{ marginTop: 10 }}
                  onChange={(e) => {
                    props.handleFormChange(e.target.name, e.target.value);
                  }}
                />
              </Form.Group>
            )}

            {props.formData.assetType === "bar_chart" && (
              <Form.Group controlId="formBasicText1">
                <Form.Label
                  style={{ marginBottom: 0, marginTop: 10 }}
                  className="demo-form-input-label"
                >
                  Chart Name
                </Form.Label>
                <Form.Control
                  name="chartName"
                  size="sm"
                  type="text"
                  onChange={(e) =>
                    props.handleFormChange(e.target.name, e.target.value)
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent default action of Enter key
                    }
                  }}
                />
              </Form.Group>
            )}

            {props.formData.assetType === "executive_headshot" && (
              <Form.Select
                name="headshotShape"
                size="sm"
                aria-label="Default select example"
                style={{ marginTop: 15, marginBottom: 0, fontSize: 14 }}
                onChange={(e) =>
                  props.handleFormChange(e.target.name, e.target.value)
                }
              >
                <option>Select Headshot Shape</option>
                <option value="circle">Circle</option>
                <option value="square">Square</option>
              </Form.Select>
            )}

            {props.formData.assetType === "bar_chart" && (
              <Form.Group controlId="formBasicText1">
                <Form.Label
                  style={{ marginBottom: 0, marginTop: 10 }}
                  className="demo-form-input-label"
                >
                  Category Names
                </Form.Label>
                <Form.Control
                  name="chartCategoryNames"
                  size="sm"
                  type="text"
                  placeholder="Separated by commas"
                  onChange={(e) =>
                    props.handleFormChange(e.target.name, e.target.value)
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent default action of Enter key
                    }
                  }}
                />
                <Form.Label
                  style={{ marginBottom: 0, marginTop: 10 }}
                  className="demo-form-input-label"
                >
                  Values
                </Form.Label>
                <Form.Control
                  name="chartValues"
                  size="sm"
                  type="text"
                  placeholder="Numbers separated by commas"
                  onChange={(e) =>
                    props.handleFormChange(e.target.name, e.target.value)
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent default action of Enter key
                    }
                  }}
                />
                <Form.Label
                  style={{ marginBottom: 0, marginTop: 10 }}
                  className="demo-form-input-label"
                >
                  Chart Color
                </Form.Label>
                <Form.Control
                  name="chartHexCode"
                  type="color"
                  defaultValue={"#12385E"}
                  style={{ marginTop: 10 }}
                  onChange={(e) => {
                    props.handleFormChange(e.target.name, e.target.value);
                  }}
                />
              </Form.Group>
            )}

            {props.formData.assetType === "company_logos_from_file" && (
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label
                  size="sm"
                  className="center_horizontal demo-form-input-label"
                  style={{ marginBottom: 10, marginTop: 20 }}
                >
                  Upload Company Names .txt File
                </Form.Label>
                <div className="center_horizontal">
                  <Form.Control
                    className="center_horizontal"
                    size="sm"
                    type="file"
                    onChange={(evt) => {
                      props.setCompanyLogosTxtFile(evt.target.files[0]);
                    }}
                  />
                </div>
              </Form.Group>
            )}

            <Button
              size="sm"
              style={{ marginTop: 20, marginBottom: 10 }}
              variant="primary"
              type="button"
              disabled={props.isSubmitting}
              onClick={props.handleFormSubmit}
            >
              Create Assets
            </Button>

            {props.isSubmitting && (
              <div className="center_horizontal" style={{ marginTop: 0 }}>
                <l-tail-chase size="30" speed="1.5" color="black" />
              </div>
            )}
          </Form>

          <GridAdjuster
            imageGridBox={props.imageGridBox}
            setImageGridBox={props.setImageGridBox}
            sortImagesAlphabetically={props.sortImagesAlphabetically}
            images={props.images}
          />
        </Row>
      </Col>
    </Container>
  );
}

export default SandboxForm;
