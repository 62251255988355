import React, { useState, useEffect } from "react";
import { InputGroup, FormControl } from "react-bootstrap";

function SMPptOptionsForm({ pptOptions, setPptOptions, slideNumber }) {
  console.log(slideNumber);
  const dataField =
    slideNumber === 3 ? "includeInNTMCompsSlides" : "includeInLTMCompsSlides";
  const isIndustrySlide = slideNumber <= 4 + pptOptions.length;
  console.log(dataField);
  let industryName;
  let companiesList;
  let items;
  if (!isIndustrySlide) {
    const barChartSlideIndex = slideNumber - (pptOptions.length + 5);
    industryName = pptOptions[Math.floor(barChartSlideIndex)].industryName;
    items = pptOptions
      .filter((industry) => industry.industryName === industryName)[0]
      .companiesList.map((company) => {
        return {
          name: company.companyName,
          includeInLTMCompsSlides: company.includeInLTMCompsSlides,
        };
      });
    companiesList = pptOptions.filter(
      (industry) => industry.industryName === industryName
    )[0].companiesList;
  } else {
    items = pptOptions.map((industry) => {
      return {
        name: industry.industryName,
        [dataField]: industry[dataField],
      };
    });
  }
  console.log(items);

  const handleCheckboxChange = (name, isChecked) => {
    let updatedPptOptions;
    if (!isIndustrySlide) {
      updatedPptOptions = pptOptions.map((industry) => {
        if (industry.industryName === industryName) {
          return {
            ...industry,
            companiesList: companiesList.map((company) => {
              if (company.companyName === name) {
                return { ...company, [dataField]: isChecked };
              }
              return company;
            }),
          };
        }
        return industry;
      });
    } else {
      updatedPptOptions = pptOptions.map((industry) => {
        if (industry.industryName === name) {
          return {
            ...industry,
            [dataField]: isChecked,
          };
        }
        return industry;
      });
    }

    setPptOptions(updatedPptOptions);
  };

  return (
    <div className="checkmark-form-container">
      {items.map((item) => (
        <InputGroup style={{ width: "250px" }}>
          <InputGroup.Checkbox
            checked={item[dataField]}
            onChange={(e) => handleCheckboxChange(item.name, e.target.checked)}
          />
          <FormControl
            aria-label={item.name}
            defaultValue={item.name}
            disabled
          />
        </InputGroup>
      ))}
    </div>
  );
}

export default SMPptOptionsForm;
