import React from "react";
import { useState } from "react";
import { InputGroup } from "react-bootstrap";
import { Button, Form } from "react-bootstrap";

function WordDocArticlesForm({
  pptContent,
  setPptContent,
  formIndex,
  setFormIndex,
}) {
  const formIndexToTitleMapping = {
    0: "Portfolio News",
    1: "Industry News",
    2: "Select Financings",
  };

  const handlePortfolioCheckboxChange = (
    companyName,
    articleIndex,
    isChecked
  ) => {
    const updatedPptContent = { ...pptContent };
    updatedPptContent.portfolio_news_articles[companyName][
      articleIndex
    ].show_article = isChecked;
    setPptContent(updatedPptContent);
  };

  const handleIndustryCheckboxChange = (articleIndex, isChecked) => {
    const updatedPptContent = { ...pptContent };
    updatedPptContent.industry_news_articles[articleIndex].show_article =
      isChecked;
    setPptContent(updatedPptContent);
  };

  const handleFundraisingCheckboxChange = (articleIndex, isChecked) => {
    const updatedPptContent = { ...pptContent };
    updatedPptContent.fundraising_news_articles[articleIndex].show_article =
      isChecked;
    setPptContent(updatedPptContent);
  };

  let articlesForm = <div></div>;
  if (formIndex === 0) {
    articlesForm = (
      <div className="stepmark-pptContent-container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // This will center the column in the middle of the screen
            width: "100%",
          }}
        >
          {Object.entries(pptContent.portfolio_news_articles).map(
            ([companyName, articles]) =>
              articles.map((article, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start", // This ensures the row content aligns to the left
                    width: "500px", // Adjust the width as necessary to fit the content
                    paddingBottom: "20px",
                  }}
                >
                  <InputGroup.Checkbox
                    checked={article.show_article}
                    onChange={(e) =>
                      handlePortfolioCheckboxChange(
                        companyName,
                        index,
                        e.target.checked
                      )
                    }
                  />
                  <div style={{ paddingLeft: "20px" }}>
                    {article.title + " (" + article.date + ")"}
                  </div>
                </div>
              ))
          )}
        </div>
      </div>
    );
  } else if (formIndex === 1) {
    articlesForm = (
      <div className="stepmark-pptContent-container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // This will center the column in the middle of the screen
            width: "100%",
          }}
        >
          {pptContent.industry_news_articles.map((article, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start", // This ensures the row content aligns to the left
                width: "500px", // Adjust the width as necessary to fit the content
                paddingBottom: "20px",
              }}
            >
              <InputGroup.Checkbox
                checked={article.show_article}
                onChange={(e) =>
                  handleIndustryCheckboxChange(index, e.target.checked)
                }
              />
              <div style={{ paddingLeft: "20px" }}>
                {article.title + " (" + article.date + ")"}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  } else if (formIndex === 2) {
    articlesForm = (
      <div className="stepmark-pptContent-container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // This will center the column in the middle of the screen
            width: "100%",
          }}
        >
          {pptContent.fundraising_news_articles.map((article, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start", // This ensures the row content aligns to the left
                width: "500px", // Adjust the width as necessary to fit the content
                paddingBottom: "20px",
              }}
            >
              <InputGroup.Checkbox
                checked={article.show_article}
                onChange={(e) =>
                  handleFundraisingCheckboxChange(index, e.target.checked)
                }
              />
              <div style={{ paddingLeft: "20px" }}>
                {article.content + " (" + article.date + ")"}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div>
      <div
        // className="center_horizontal"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "50px",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center", marginRight: "40px" }}
        >
          <Button
            variant="primary"
            type="button"
            onClick={() => setFormIndex(formIndex - 1)}
            disabled={formIndex === 0}
            style={{
              height: 40,
              fontWeight: "bold",
            }}
          >
            {"<"}
          </Button>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* {articlesForm} */}
          <Form.Label
            size="sm"
            className="center_horizontal demo-form-input-label"
            style={{ fontSize: "24px" }}
          >
            {formIndexToTitleMapping[formIndex]}
          </Form.Label>
        </div>
        <div
          style={{ display: "flex", alignItems: "center", marginLeft: "40px" }}
        >
          <Button
            variant="primary"
            type="button"
            onClick={() => setFormIndex(formIndex + 1)}
            disabled={formIndex === 2}
            style={{
              height: 40,
              fontWeight: "bold",
            }}
          >
            {">"}
          </Button>
        </div>
      </div>
      {articlesForm}
    </div>
  );
}

export default WordDocArticlesForm;
