import React from "react";
import { InputGroup, Form, Col, Row, Button } from "react-bootstrap";

function NewsArticlesForm({
  pptContent,
  setPptContent,
  slideNumber,
  firstCompetitorSlideIndex,
}) {
  let companyName;
  if (slideNumber - 1 < firstCompetitorSlideIndex) {
    companyName = pptContent.target_company_name;
  } else {
    const competitorsIndex = slideNumber - 1 - firstCompetitorSlideIndex;
    companyName = pptContent.all_competitors[competitorsIndex];
  }

  const handleCheckboxChange = (index, isChecked) => {
    const updatedPptContent = { ...pptContent };
    updatedPptContent.news_content[companyName][index].include_in_slide =
      isChecked;
    setPptContent(updatedPptContent);
  };

  const handleRowChange = (index, fieldName, value) => {
    setPptContent((prevState) => ({
      ...prevState,
      user_input_articles: {
        ...prevState.user_input_articles,
        [companyName]: prevState.user_input_articles[companyName].map(
          (article, i) =>
            i === index ? { ...article, [fieldName]: value } : article
        ),
      },
    }));
  };

  const handleRemoveRow = (index) => {
    setPptContent((prevState) => ({
      ...prevState,
      user_input_articles: {
        ...prevState.user_input_articles,
        [companyName]: prevState.user_input_articles[companyName].filter(
          (_, i) => i !== index
        ),
      },
    }));
  };

  const handleAddRow = () => {
    const newRow = {
      company_name: companyName,
      link: "",
      source: "",
      month: "",
      year: "",
    };
    setPptContent((prevState) => ({
      ...prevState,
      user_input_articles: {
        ...prevState.user_input_articles,
        [companyName]: [
          ...(prevState.user_input_articles[companyName] || []),
          newRow,
        ],
      },
    }));
  };

  console.log(pptContent);

  return (
    <div className="stepmark-pptContent-container">
      {pptContent.news_content[companyName] ? (
        pptContent.news_content[companyName].map((newsArticle, index) => (
          <InputGroup>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingBottom: "20px",
              }}
            >
              <InputGroup.Checkbox
                checked={newsArticle.include_in_slide}
                onChange={(e) => handleCheckboxChange(index, e.target.checked)}
              />
              {companyName === pptContent.target_company_name && (
                <div style={{ paddingLeft: "20px" }}>
                  {newsArticle.summary_sentences[0] +
                    " (" +
                    newsArticle.publisher +
                    ", " +
                    newsArticle.date +
                    ")"}
                </div>
              )}
              {companyName !== pptContent.target_company_name && (
                <div style={{ paddingLeft: "20px" }}>
                  {newsArticle.title +
                    " (" +
                    newsArticle.publisher +
                    ", " +
                    newsArticle.date +
                    ")"}
                </div>
              )}
            </div>
          </InputGroup>
        ))
      ) : (
        <div></div>
      )}
      <Form.Label
        size="sm"
        className="center_horizontal demo-form-input-label"
        style={{ marginBottom: 30, marginTop: 20, fontSize: "24px" }}
      >
        Add Articles Manually
      </Form.Label>
      {pptContent.user_input_articles[companyName] ? (
        pptContent.user_input_articles[companyName].map((row, index) => (
          <Row className="mb-3" key={index} id={index}>
            <Col>
              <Form.Control
                type="text"
                placeholder="Insert Url"
                value={row.link}
                onChange={(e) => handleRowChange(index, "link", e.target.value)}
              />
            </Col>
            <Col>
              <Form.Control
                type="text"
                placeholder="Insert Publisher"
                value={row.source}
                onChange={(e) =>
                  handleRowChange(index, "source", e.target.value)
                }
              />
            </Col>
            <Col>
              <Form.Select
                name="sortType"
                aria-label="Default select example"
                style={{
                  fontSize: 16,
                  marginLeft: "40px",
                  width: "150px",
                }}
                value={row.month}
                onChange={(e) =>
                  handleRowChange(index, "month", e.target.value)
                }
              >
                <option value="">Select Month</option>
                <option value="Jan">Jan</option>
                <option value="Feb">Feb</option>
                <option value="Mar">Mar</option>
                <option value="Apr">Apr</option>
                <option value="May">May</option>
                <option value="Jun">Jun</option>
                <option value="Jul">Jul</option>
                <option value="Aug">Aug</option>
                <option value="Sep">Sep</option>
                <option value="Oct">Oct</option>
                <option value="Nov">Nov</option>
                <option value="Dec">Dec</option>
              </Form.Select>
            </Col>
            <Col>
              <Form.Control
                type="text"
                placeholder="Insert Year"
                value={row.year}
                onChange={(e) => handleRowChange(index, "year", e.target.value)}
              />
            </Col>
            <Col xs="auto">
              <Button
                className="tombstone-add-remove-button"
                variant="danger"
                onClick={() => handleRemoveRow(index)}
              >
                -
              </Button>
            </Col>
          </Row>
        ))
      ) : (
        <div></div>
      )}
      <Button
        onClick={handleAddRow}
        className="my-3"
        style={{
          width: "100px",
          margin: "auto",
          marginBottom: 50,
          fontSize: "20px",
        }}
      >
        +
      </Button>
    </div>
  );
}

export default NewsArticlesForm;
