import React from "react";
import { InputGroup, Form, Button, Col, Row } from "react-bootstrap";

function SMPptContentForm({
  pptOptions,
  pptContent,
  setPptContent,
  slideNumber,
}) {
  const newsSlideIndex = slideNumber - 4;
  const industryName = pptOptions[newsSlideIndex].industryName;

  const handleCheckboxChange = (index, isChecked) => {
    const numUserAddedArticles = pptContent[industryName].reduce(
      (count, element) => {
        return count + (element.is_user_added === true ? 1 : 0);
      },
      0
    );
    const updatedPptContent = { ...pptContent };
    updatedPptContent[industryName][
      index + numUserAddedArticles
    ].include_article = isChecked;
    setPptContent(updatedPptContent);
  };

  const handleAddRow = () => {
    const newRow = {
      link: "",
      source: "",
      is_user_added: true,
    };
    setPptContent((prevState) => ({
      ...prevState,
      [industryName]: [newRow, ...prevState[industryName]],
    }));
  };

  const handleRowChange = (index, fieldName, value) => {
    setPptContent((prevState) => ({
      ...prevState,
      [industryName]: prevState[industryName].map((article, i) =>
        i === index ? { ...article, [fieldName]: value } : article
      ),
    }));
  };

  const handleRemoveRow = (index) => {
    setPptContent((prevState) => ({
      ...prevState,
      [industryName]: prevState[industryName].filter((_, i) => i !== index),
    }));
  };

  return (
    <div className="stepmark-pptContent-container">
      {pptContent[industryName]
        .filter((newsArticle) => newsArticle.is_user_added === false)
        .map((newsArticle, index) => (
          <InputGroup>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingBottom: "20px",
              }}
            >
              <InputGroup.Checkbox
                checked={newsArticle.include_article}
                onChange={(e) => handleCheckboxChange(index, e.target.checked)}
              />
              {newsArticle.summary_sentences && (
                <div style={{ paddingLeft: "20px" }}>
                  {newsArticle.summary_sentences[0] +
                    " (" +
                    newsArticle.source +
                    ", " +
                    newsArticle.date +
                    ")"}
                </div>
              )}
            </div>
          </InputGroup>
        ))}
      <Form.Label
        size="sm"
        className="center_horizontal demo-form-input-label"
        style={{ marginBottom: 30, marginTop: 20, fontSize: "24px" }}
      >
        Add Articles Manually
      </Form.Label>
      {pptContent[industryName]
        .filter((newsArticle) => newsArticle.is_user_added === true)
        .map((newsArticle, index) => (
          <Row className="mb-3" key={index} id={index}>
            <Col>
              <Form.Control
                type="text"
                placeholder="Article Url"
                value={newsArticle.link}
                onChange={(e) => handleRowChange(index, "link", e.target.value)}
              />
            </Col>
            <Col>
              <Form.Control
                type="text"
                placeholder="Publisher Name"
                value={newsArticle.source}
                onChange={(e) =>
                  handleRowChange(index, "source", e.target.value)
                }
              />
            </Col>
            <Col xs="auto">
              <Button
                className="tombstone-add-remove-button"
                variant="danger"
                onClick={() => handleRemoveRow(index)}
              >
                -
              </Button>
            </Col>
          </Row>
        ))}
      <Button
        onClick={handleAddRow}
        className="my-3"
        style={{
          width: "100px",
          margin: "auto",
          marginBottom: 50,
          fontSize: "20px",
        }}
      >
        +
      </Button>
    </div>
  );
}

export default SMPptContentForm;
