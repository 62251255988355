import React, { useState } from "react";
import "./GridAdjuster.css"; // Make sure to create this CSS file
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";

const GridAdjuster = ({
  imageGridBox,
  setImageGridBox,
  sortImagesAlphabetically,
  images,
}) => {
  const adjustRows = (increment) => {
    setImageGridBox((prev) => ({
      ...prev,
      numRows: increment ? prev.numRows + 1 : Math.max(prev.numRows - 1, 1),
      numCols:
        increment === false &&
        prev.numRows > 1 &&
        (prev.numRows - 1) * prev.numCols < images.length
          ? Math.ceil(images.length / (prev.numRows - 1))
          : prev.numCols,
    }));
  };

  // Function to handle column changes
  const adjustCols = (increment) => {
    setImageGridBox((prev) => ({
      ...prev,
      numCols: increment ? prev.numCols + 1 : Math.max(prev.numCols - 1, 1),
      numRows:
        increment === false &&
        prev.numCols > 1 &&
        (prev.numCols - 1) * prev.numRows < images.length
          ? Math.ceil(images.length / (prev.numCols - 1))
          : prev.numRows,
    }));
  };

  const adjustSize = (size) => {
    setImageGridBox((prev) => ({
      ...prev,
      size,
    }));
  };

  return (
    // <div className="grid-adjuster">
    <div style={{ marginTop: "40px", marginBottom: "15px" }}>
      <div className="adjuster">
        <div className="rows-label">Rows</div>
        <div className="controls">
          <div>
            <button
              onClick={() => adjustRows(true)}
              className="arrow up"
            ></button>
            <button
              onClick={() => adjustRows(false)}
              className="arrow down"
            ></button>
          </div>
          <div className="number-box">{imageGridBox.numRows}</div>
        </div>
      </div>
      <div className="adjuster">
        <div className="cols-label">Columns</div>
        <div className="controls">
          <div>
            <button
              onClick={() => adjustCols(true)}
              className="arrow up"
            ></button>
            <button
              onClick={() => adjustCols(false)}
              className="arrow down"
            ></button>
          </div>
          <div className="number-box">{imageGridBox.numCols}</div>
        </div>
      </div>
      <Form.Label style={{ fontSize: "16px", marginTop: 0 }}>
        Image Size
      </Form.Label>
      <Form.Range
        value={imageGridBox.size}
        min={20}
        onChange={(e) => adjustSize(e.target.value)}
        // style={{ width: "58.5%" }}
      />
      <Button
        size="sm"
        variant="primary"
        type="button"
        onClick={() => sortImagesAlphabetically()}
        style={{ width: "100%" }}
      >
        Sort Images Alphabetically
      </Button>
    </div>
  );
};

export default GridAdjuster;
