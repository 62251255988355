import { useState } from "react";
import { Link } from "react-router-dom";
import { getCurrentUser } from "aws-amplify/auth";
import Container from "react-bootstrap/Container";
import { Form, Col, Row, Button } from "react-bootstrap";
import { signOut } from "aws-amplify/auth";

import { tailChase } from "ldrs";

function DemoForm() {
  const [companyName, setCompanyName] = useState("");
  const [stockTicker, setStockTicker] = useState("");
  const [companyWebsiteUrl, setCompanyWebsiteUrl] = useState("");
  const [powerPointType, setPowerPointType] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showFormErrors, setShowFormErrors] = useState(false);

  const isCompanyWebsiteUrlValid = () => {
    return (
      companyWebsiteUrl.length < 30 &&
      companyWebsiteUrl.indexOf("http") === -1 &&
      companyWebsiteUrl.indexOf("www") === -1 &&
      companyWebsiteUrl.indexOf("/") === -1 &&
      companyWebsiteUrl.indexOf(":") === -1
    );
  };

  const isFormValid = () => {
    return (
      companyName !== "" &&
      companyName.length <= 30 &&
      stockTicker.length <= 30 &&
      powerPointType !== "" &&
      isCompanyWebsiteUrlValid() &&
      (powerPointType !== "financial_overview" || stockTicker.length > 0)
    );
  };

  function sleep(ms = 0) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const getCurrentDate = () => {
    const dateObj = new Date();
    const currentDay = new Date().getDate();
    const currentMonth = dateObj.getMonth() + 1;
    const currentYear = dateObj.getFullYear();
    return currentMonth + "-" + currentDay + "-" + currentYear;
  };

  const handleSubmit = async () => {
    let loginId;
    try {
      let user = await getCurrentUser();
      loginId = user.signInDetails.loginId;
    } catch (error) {
      console.log(error);
      await signOut();
    }

    if (loginId === undefined) {
      return;
    }
    if (!isFormValid()) {
      setShowFormErrors(true);
    } else {
      setIsSubmitting(true);
      const emailParam = "&email=".concat(loginId);

      const companyNameParam = "company_name=".concat(
        companyName.replace("&", "and")
      );
      const stockTickerParam =
        stockTicker !== "" ? "&stock_ticker=".concat(stockTicker) : "";
      const companyWebsiteUrlParam =
        companyWebsiteUrl !== ""
          ? "&company_website_url=".concat(companyWebsiteUrl)
          : "";
      const powerPointTypeParam = "&ppt_type=".concat(powerPointType);
      const currentDate = getCurrentDate();
      const dateParam = "&date=".concat(currentDate);

      const first_word_in_ppt_type = powerPointType.split("_")[0];
      const second_word_in_ppt_type = powerPointType.split("_")[1];
      const fileName = companyName.concat(
        " ",
        first_word_in_ppt_type.charAt(0).toUpperCase() +
          first_word_in_ppt_type.slice(1),
        " ",
        second_word_in_ppt_type.charAt(0).toUpperCase() +
          second_word_in_ppt_type.slice(1),
        "_",
        currentDate.replaceAll("-", "."),
        ".pptx"
      );
      const apiEndpoint =
        "https://lucite-backend.com/generate_powerpoint?".concat(
          companyNameParam,
          emailParam,
          stockTickerParam,
          companyWebsiteUrlParam,
          powerPointTypeParam,
          dateParam
        );
      await fetch(apiEndpoint, {
        method: "GET",
        headers: {
          "Content-Type":
            "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          "Cache-Control": "private, no-store, max-age=0",
        },
      })
        .then((response) => response.blob())
        .then(async (blob) => {
          setIsSubmitting(false);
          await sleep(100); // Needed so spinner goes away before alert
          if (blob.size > 1000) {
            const url = window.URL.createObjectURL(new Blob([blob]));

            const link = document.createElement("a");
            link.href = url;
            link.download = fileName;

            document.body.appendChild(link);

            link.click();

            link.parentNode.removeChild(link);
          } else {
            const alertMessage =
              powerPointType === "financial_overview"
                ? "Unable to fetch financial data for this stock ticker"
                : "An error occurred while generating your PowerPoint";
            alert(alertMessage);
          }
        })
        .catch(async function (error) {
          setIsSubmitting(false);
          await sleep(100); // Needed so spinner goes away before alert
          alert("Unable to generate PowerPoint at this time");
          const formData = new FormData();
          const errorData = {
            errorLocation: "Demo Form Submit",
            errorName: error,
            errorMessage: error.message,
          };
          const jsonBlob = new Blob([JSON.stringify(errorData)], {
            type: "application/json",
          });
          formData.append("jsonData", jsonBlob);
          fetch("https://lucite-backend.com/log_react_error", {
            method: "POST",
            body: formData,
          });
        });
    }
  };

  tailChase.register();

  return (
    <Container style={{ marginTop: 50 }}>
      <Col>
        <Row>
          <Form className="form">
            <Form.Select
              aria-label="Default select example"
              style={{ marginTop: 10, marginBottom: 0 }}
              onChange={(evt) => {
                setPowerPointType(evt.target.value);
                setShowFormErrors(false);
              }}
            >
              <option>Select PowerPoint Type</option>
              <option value="business_overview">Business Overview</option>
              <option value="competitive_overview">Competitive Overview</option>
              <option value="financial_overview">
                Financial Overview (public only)
              </option>
              <option value="buyers_list">Buyers List</option>
            </Form.Select>
            {showFormErrors && powerPointType === "" ? (
              <p className="error center_horizontal">
                Please select a PowerPoint type
              </p>
            ) : null}

            <Form.Group controlId="formBasicText1">
              <Form.Label
                style={{ marginBottom: 0, marginTop: 10 }}
                className="center_horizontal"
              >
                Company Name
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(evt) => {
                  setCompanyName(evt.target.value);
                  setShowFormErrors(false);
                }}
              />
              {showFormErrors && companyName === "" ? (
                <p className="error center_horizontal">
                  This field is required
                </p>
              ) : null}
              {showFormErrors && companyName.length > 30 ? (
                <p className="error center_horizontal">
                  Max length is 30 characters
                </p>
              ) : null}
            </Form.Group>

            <Form.Group className="form-group" controlId="formBasicText2">
              <Form.Label
                style={{ marginBottom: 0 }}
                className="center_horizontal"
              >
                Stock Ticker
              </Form.Label>
              <Col className="center_horizontal">
                <Form.Label style={{ marginTop: 0 }} className="form_body">
                  Leave blank for private companies
                </Form.Label>
              </Col>
              <Form.Control
                type="text"
                onChange={(evt) => {
                  setStockTicker(evt.target.value);
                  setShowFormErrors(false);
                }}
              />
              {showFormErrors && stockTicker.length > 30 ? (
                <p className="error center_horizontal">
                  Max length is 30 characters
                </p>
              ) : null}
              {showFormErrors && powerPointType === "financial_overview" ? (
                <p className="error center_horizontal">
                  Stock ticker is required for financial overview powerpoints
                </p>
              ) : null}
            </Form.Group>

            <Form.Group className="form-group" controlId="formBasicText3">
              <Form.Label
                style={{ marginBottom: 0 }}
                className="center_horizontal"
              >
                Company Website Url
              </Form.Label>
              <Col className="center_horizontal">
                <Form.Label style={{ marginTop: 0 }} className="form_body">
                  Optional field. Include the base url only with no "https" or
                  "www".
                </Form.Label>
              </Col>
              <Form.Control
                type="text"
                placeholder="example.com"
                onChange={(evt) => {
                  setCompanyWebsiteUrl(evt.target.value);
                  setShowFormErrors(false);
                }}
              />
              {showFormErrors && companyWebsiteUrl.length > 30 ? (
                <p className="error center_horizontal">
                  Max length is 30 characters
                </p>
              ) : null}
              {showFormErrors && !isCompanyWebsiteUrlValid() ? (
                <p className="error center_horizontal">
                  Only include the base url with no http, www, :, or /
                </p>
              ) : null}
            </Form.Group>

            <Col className="center_horizontal">
              <Form.Label
                style={{ marginTop: 30, marginBottom: 0 }}
                className="form_body"
              >
                Each PowerPoint takes up to 5 minutes to generate.
              </Form.Label>
            </Col>
            <Col className="center_horizontal">
              <Form.Label style={{ marginTop: 0 }} className="form_body">
                The file will download automatically.
              </Form.Label>
            </Col>
            <Button
              style={{ marginTop: 0 }}
              variant="primary"
              type="button"
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              Create PowerPoint
            </Button>
            <p style={{ marginTop: 0 }} className="form_body center_horizontal">
              Check the slide notes for source urls and spillover text!
            </p>
          </Form>
          <div className="center_horizontal" style={{ marginTop: 0 }}>
            <Link
              to="https://docs.google.com/forms/d/e/1FAIpQLSfrOB8HRkKM1-U83rkxDR7CDvq_nn3X37b5A0215ptlwPITnQ/viewform"
              target="_blank"
              rel="noopener noreferrer"
            >
              Send us feedback!
            </Link>
          </div>
          {isSubmitting && (
            <div className="center_horizontal" style={{ marginTop: 20 }}>
              <l-tail-chase size="50" speed="1.5" color="black" />
            </div>
          )}
        </Row>
      </Col>
    </Container>
  );
}

export default DemoForm;
