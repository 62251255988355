import React, { useState } from "react";
import { Rnd } from "react-rnd";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

class ExcludeButtonPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: "onPointerDown",
      handler: ({ nativeEvent: event }) => {
        return !event.target.outerHTML.includes("button");
      },
    },
  ];
}

function SortableItem({ id, imageStyle, images, setImages, url, index }) {
  const [mouseIsOver, setMouseIsOver] = useState(false);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      onMouseEnter={() => {
        setMouseIsOver(true);
      }}
      onMouseLeave={() => {
        setMouseIsOver(false);
      }}
      className="image-cell"
      id={url}
      key={url}
      tabIndex={-1}
    >
      <img src={url} alt={`Image ${index + 1}`} style={imageStyle} />
      {mouseIsOver && (
        <button
          className="image-delete-button"
          onClick={(event) => {
            event.stopPropagation();
            const newImages = [
              ...images.slice(0, index),
              ...images.slice(index + 1),
            ];
            setImages(newImages);
          }}
        >
          x
        </button>
      )}
    </div>
  );
}

const ImageGrid = (props) => {
  const sensors = useSensors(
    useSensor(ExcludeButtonPointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const gridStyle = {
    display: "grid",
    gridTemplateColumns: `repeat(${props.box.numCols}, 1fr)`, // Variable number of columns
    gridTemplateRows: `repeat(${props.box.numRows}, 1fr)`, // Variable number of rows
    gap: "10px",
    width: "100%", // Takes full width of its container
    height: "100%", // Takes full height of its container
    minWidth: "0", // Helps prevent overflow
    minHeight: "0", // Helps prevent overflow
  };
  const imageStyle = {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain" /* Ensures the image maintains its aspect ratio */,
    width: `${props.box.size}%`,
    height: `${props.box.size}%`,
  };
  const dragHandleStyle = {
    cursor: "move",
    height: "20px",
    width: "20px",
    backgroundColor: "#ccc",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "-25px", // Position the drag handle above the grid
    right: "5px", // Position it towards the right edge of the grid
    borderRadius: "50%",
    zIndex: 1000, // Ensure it's above other content
  };

  return (
    <Rnd
      key={props.box.id}
      size={{ width: props.box.width, height: props.box.height }}
      position={{ x: props.box.x, y: props.box.y }}
      onDragStop={(e, d) => props.onDragStop(props.box.id, e, d)}
      onResizeStop={(e, direction, ref, delta, position) => {
        props.onResizeStop(props.box.id, e, direction, ref, delta, position);
      }}
      minWidth={100}
      minHeight={100}
      bounds="parent"
      style={{ outline: "2px dashed blue" }}
      dragHandleClassName="drag-handle"
    >
      <div className="drag-handle" style={dragHandleStyle}></div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <div style={gridStyle}>
          <SortableContext items={props.images.map((image) => image.url)}>
            {props.images.map((image, index) => (
              <SortableItem
                key={image.url}
                id={image.url}
                index={index}
                imageStyle={imageStyle}
                url={image.url}
                images={props.images}
                setImages={props.setImages}
              />
            ))}
          </SortableContext>
        </div>
      </DndContext>
    </Rnd>
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      props.setImages(() => {
        const oldIndex = props.images.findIndex(
          (image) => image.url === active.id
        );
        const newIndex = props.images.findIndex(
          (image) => image.url === over.id
        );
        return arrayMove(props.images, oldIndex, newIndex);
      });
    }
  }
};

export default ImageGrid;
