import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { Col, Button } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import { tailChase } from "ldrs";
import SMPptOptionsForm from "./SMPptOptionsForm";
import SMPptContentForm from "./SMPptContentForm";
import CompetitorBucketsForm from "./CompanyOverviewDeck/CompetitorBucketsForm";
import ProductsForm from "./CompanyOverviewDeck/ProductsForm";
import NewsArticlesForm from "./CompanyOverviewDeck/NewsArticlesForm";
import PublicCompsForm from "./CompanyOverviewDeck/PublicCompsForm";
import WordDocArticlesForm from "./Customer_3/WordDocArticlesForm";
import IndustryFinancialsForm from "./Customer_3/IndustryFinancialsForm";

import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function SlideViewer(props) {
  const [numPages, setNumPages] = useState(3);
  const [blockFormRender, setBlockFormRender] = useState(true);
  const [blockScrollButtons, setBlockScrollButtons] = useState(true);
  const [wordDocFormIndex, setWordDocFormIndex] = useState(0);

  useEffect(() => {
    const sleep100 = async () => {
      await sleep(100);
      setBlockFormRender(false);
    };
    sleep100();
  });

  async function onDocumentLoadSuccess(pdf) {
    setNumPages(pdf.numPages);
    await sleep(10);
    setBlockScrollButtons(false);
  }

  async function handlePrevClick() {
    setBlockFormRender(true);
    setBlockScrollButtons(true);
    props.setCurrentSlideNumber(props.currentSlideNumber - 1);
    await sleep(1);
    setBlockFormRender(false);
    setBlockScrollButtons(false);
  }

  async function handleNextClick() {
    setBlockFormRender(true);
    setBlockScrollButtons(true);
    props.setCurrentSlideNumber(props.currentSlideNumber + 1);
    await sleep(1);
    setBlockFormRender(false);
    setBlockScrollButtons(false);
  }

  function handleBackClick() {
    props.setCurrentSlideNumber(-1);
  }

  function sleep(ms = 0) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  tailChase.register();

  return (
    <Container>
      <Col>
        <Button
          variant="primary"
          type="button"
          onClick={handleBackClick}
          style={{
            height: 40,
            width: 40,
            marginLeft: 20,
            fontWeight: "bold",
            backgroundColor: "#12385E",
            borderColor: "#12385E",
          }}
        >
          {"<"}
        </Button>
      </Col>
      <Col style={{ marginTop: 0 }}>
        <div className="center_horizontal">
          {!blockScrollButtons && (
            <Button
              variant="primary"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                handlePrevClick();
              }}
              disabled={props.currentSlideNumber === 1}
              style={{
                height: 40,
                marginRight: 40,
                fontWeight: "bold",
              }}
            >
              {"<"}
            </Button>
          )}
          <Document
            loading={""}
            file={props.pdfUrl}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <div style={{ boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.2)" }}>
              <Page
                key={props.currentSlideNumber}
                loading={""}
                width={
                  props.studioFormData.powerPointType === "news_update"
                    ? 500
                    : 800
                }
                pageNumber={props.currentSlideNumber}
              />
            </div>
          </Document>
          {!blockScrollButtons && (
            <Button
              variant="primary"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                handleNextClick();
              }}
              disabled={props.currentSlideNumber === numPages}
              style={{
                height: 40,
                marginLeft: 40,
                fontWeight: "bold",
              }}
            >
              {">"}
            </Button>
          )}
        </div>

        {!blockFormRender &&
          props.studioFormData.powerPointType === "industry_newsletter" &&
          (props.currentSlideNumber === 3 ||
            props.currentSlideNumber > props.pptOptions.length + 3) &&
          props.currentSlideNumber < 17 && (
            <SMPptOptionsForm
              pptOptions={props.pptOptions}
              setPptOptions={props.setPptOptions}
              slideNumber={props.currentSlideNumber}
            />
          )}
        {!blockFormRender &&
          props.studioFormData.powerPointType === "industry_newsletter" &&
          props.currentSlideNumber > 3 &&
          props.currentSlideNumber <= props.pptOptions.length + 3 && (
            <SMPptContentForm
              pptOptions={props.pptOptions}
              pptContent={props.pptContent}
              setPptContent={props.setPptContent}
              slideNumber={props.currentSlideNumber}
            />
          )}
        {!blockFormRender &&
          props.studioFormData.powerPointType === "company_overview" &&
          props.pptFormMappingInfo.slides_form_map.products.includes(
            props.currentSlideNumber - 1
          ) && (
            <ProductsForm
              pptContent={props.pptContent}
              setPptContent={props.setPptContent}
            />
          )}
        {!blockFormRender &&
          props.studioFormData.powerPointType === "company_overview" &&
          props.pptFormMappingInfo.slides_form_map.competitor_buckets.includes(
            props.currentSlideNumber - 1
          ) && (
            <CompetitorBucketsForm
              pptContent={props.pptContent}
              setPptContent={props.setPptContent}
            />
          )}
        {!blockFormRender &&
          props.studioFormData.powerPointType === "company_overview" &&
          props.pptFormMappingInfo.slides_form_map.news_articles.includes(
            props.currentSlideNumber - 1
          ) && (
            <NewsArticlesForm
              pptContent={props.pptContent}
              setPptContent={props.setPptContent}
              slideNumber={props.currentSlideNumber}
              firstCompetitorSlideIndex={
                props.pptFormMappingInfo.first_competitor_slide_index
              }
            />
          )}
        {!blockFormRender &&
          props.studioFormData.powerPointType === "company_overview" &&
          props.pptFormMappingInfo.slides_form_map.public_comps.includes(
            props.currentSlideNumber - 1
          ) && (
            <PublicCompsForm
              pptContent={props.pptContent}
              setPptContent={props.setPptContent}
            />
          )}
        {props.studioFormData.powerPointType === "news_update" && (
          <WordDocArticlesForm
            pptContent={props.pptContent}
            setPptContent={props.setPptContent}
            formIndex={wordDocFormIndex}
            setFormIndex={setWordDocFormIndex}
          />
        )}
        {!blockFormRender &&
          props.studioFormData.powerPointType === "industry_financials" &&
          props.currentSlideNumber > 3 &&
          props.currentSlideNumber !== numPages && (
            <IndustryFinancialsForm
              pptContent={props.pptContent}
              setPptContent={props.setPptContent}
              slideNumber={props.currentSlideNumber}
            />
          )}
      </Col>
    </Container>
  );
}

export default SlideViewer;
