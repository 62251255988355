import React from "react";

const ModelAbstractorInstructions = ({ pptContent }) => {
  // Determine the content for the additional sentence based on pptContent
  const getNetIncomeText = () => {
    if (
      pptContent &&
      typeof pptContent === "object" &&
      !Array.isArray(pptContent)
    ) {
      if (pptContent.does_net_income_tie === true) {
        return {
          text: "Output model's net income ties to the input model's net income",
          color: "green",
        };
      } else if (pptContent.does_net_income_tie === false) {
        return {
          text: "Output model's net income does not tie to the input model's net income",
          color: "red",
        };
      }
    }
    return null;
  };

  function downloadTemplateExcelFile() {
    const link = document.createElement("a");
    link.href = "sc_model_template.xlsx";
    link.download = "ModelTemplate.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const netIncomeText = getNetIncomeText();

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <ul
        style={{
          listStylePosition: "outside",
          textAlign: "left",
          fontSize: "20px",
          maxWidth: "60%",
          paddingTop: "60px",
        }}
      >
        <li>
          Click{" "}
          <span
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={downloadTemplateExcelFile}
          >
            here
          </span>{" "}
          to download the Lucite clean template. Copy and paste values and
          formatting from the management model into the respective{" "}
          <em>Income Statement</em>, <em>Balance Sheet</em>, and{" "}
          <em>Cash Flow</em> worksheets.
        </li>
        <li>
          Remove any unnecessary rows or columns that may confuse the software.
        </li>
        <li>
          Confirm that the management model consistently reflects values in the
          P&L statement. Specifically, all values that are typically subtracted
          (such as COGS or OPEX) should be displayed as positive numbers. Review
          and adjust the template for consistency where needed. For line items
          that are usually subtracted but need to be added instead (such as when
          there is interest income rather than interest expense), show those
          values as negative.
        </li>
        <li>
          Re-label all quarter headers in the format Q1, Q2, etc and all years
          as the full year number without any abbreviations (ex. 2023)
        </li>
      </ul>

      {netIncomeText && (
        <p
          style={{
            color: netIncomeText.color,
            marginTop: "20px",
            fontSize: "20px",
          }}
        >
          {netIncomeText.text}
        </p>
      )}
    </div>
  );
};

export default ModelAbstractorInstructions;
