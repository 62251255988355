import React from "react";
import { InputGroup, FormControl } from "react-bootstrap";

function ProductsForm({ pptContent, setPptContent }) {
  const handleCheckboxChange = (productName, isChecked) => {
    setPptContent((prevState) => ({
      ...prevState, // Maintain the structure of the top-level state
      products_overview: {
        ...prevState.products_overview, // Maintain all other properties of products_overview
        content: prevState.products_overview.content.map((product) =>
          product.product_name === productName
            ? { ...product, include_in_slide: isChecked } // Only update include_in_slide if the product name matches
            : product
        ),
      },
    }));
  };

  return (
    <div className="checkmark-form-container">
      {pptContent.products_overview.content.map((product) => (
        <InputGroup style={{ width: "250px" }}>
          <InputGroup.Checkbox
            checked={product.include_in_slide}
            onChange={(e) =>
              handleCheckboxChange(product.product_name, e.target.checked)
            }
          />
          <FormControl
            aria-label={product.product_name}
            defaultValue={product.product_name}
            disabled
          />
        </InputGroup>
      ))}
    </div>
  );
}

export default ProductsForm;
