import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "aws-amplify/auth";
import Container from "react-bootstrap/Container";
import { Form, Col, Row, Button } from "react-bootstrap";
import { tailChase } from "ldrs";
import { sleep } from "../Utils.js";
import { fetchAuthSession } from "aws-amplify/auth";

function StudioForm(props) {
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [showBackendErrorMessage, setShowBackendErrorMessage] = useState(false);
  const [inputKey, setInputKey] = useState(Date.now()); // used to reset the file input component after the user clicks "Clear Files"

  const pptTypeToFormElements = {
    "": [],
    industry_newsletter: [],
    news_update: [],
    industry_financials: ["industry_picker"],
    pdf_parser: ["pdf_parser"],
    real_estate_parser: ["pdf_parser", "pdf_type"],
    lease_parser: ["pdf_parser"],
    firm_overview: ["company_name"],
    company_overview: [
      "company_name",
      "crunchbase_id",
      "google_search_query",
      "competitive_landscape_query",
      "company_overview_deck_type",
      "competitors_search_type",
      "founder_names_string",
      "leadership_team_url",
    ],
    company_memo: ["company_name", "crunchbase_id", "pdf_parser"],
    rg_demo: ["json_file_uploader"],
    model_parser: [
      "model_uploader",
      "cash_amount",
      "actual_time_period",
      "equity_amount",
      "debt_amount",
      "funding_time_period",
    ],
  };

  const handleSubmit = async () => {
    setShowBackendErrorMessage(false);
    let loginId;
    try {
      let user = await getCurrentUser();
      loginId = user.signInDetails.loginId;
    } catch (error) {
      navigate("/login/session_expired");
    }

    if (loginId === undefined) {
      setShowFormErrors(true);
    } else {
      setIsSubmitting(true);
      const apiEndpoint = props.getStudioAPIEndpoint();

      const formData = new FormData();
      const pptContentBlob = new Blob([JSON.stringify(props.pptContent)], {
        type: "application/json",
      });
      formData.append("pptContent", pptContentBlob);
      const pptOptionsBlob = new Blob([JSON.stringify(props.pptOptions)], {
        type: "application/json",
      });
      formData.append("pptOptions", pptOptionsBlob);
      const extraParamsBlob = new Blob([JSON.stringify(props.extraParams)], {
        type: "application/json",
      });
      formData.append("extraParams", extraParamsBlob);
      // for (const [index, file] of uploadedFiles.entries()) {
      //   formData.append("uploadedFile" + String(index), file);
      // }
      // for (const [index, file] of uploadedFiles.entries()) {
      //   const fileExtension = file.name.split(".").pop(); // Extract the file extension
      //   formData.append(`uploadedFile${index}.${fileExtension}`, file);
      // }
      props.uploadedFiles.forEach((file, index) => {
        formData.append(`uploadedFiles[]`, file);
      });

      const { accessToken } = (await fetchAuthSession()).tokens ?? {};

      await fetch(apiEndpoint, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: accessToken,
        },
      })
        .then(async (response) => {
          setIsSubmitting(false);
          await sleep(100); // Needed so spinner goes away before alert

          const contentType = response.headers.get("content-type");

          if (contentType.includes("application/json")) {
            // Handle JSON response
            const data = await response.json();
            if (data.error) {
              setShowBackendErrorMessage(true);
            }
          } else if (contentType.includes("application/zip")) {
            // Handle ZIP file response
            const blob = await response.blob();
            props.downloadResponseZip(blob);
          }
        })
        .catch(async function (error) {
          console.log(error);
          setIsSubmitting(false);
          await sleep(100); // Needed so spinner goes away before alert
          alert("Unable to complete request");
        });
    }
  };

  tailChase.register();

  let submitButtonText;
  if (props.studioFormData.powerPointType === "pdf_parser") {
    submitButtonText = "Send Queries";
  } else if (
    ["real_estate_parser", "lease_parser"].includes(
      props.studioFormData.powerPointType
    )
  ) {
    submitButtonText = "Abstract Documents";
  } else if (props.studioFormData.powerPointType === "model_parser") {
    submitButtonText = "Abstract Model";
  } else if (
    ["news_update", "company_memo"].includes(
      props.studioFormData.powerPointType
    ) &&
    props.pdfUrl === ""
  ) {
    submitButtonText = "Create Word Doc";
  } else if (
    ["news_update", "company_memo"].includes(
      props.studioFormData.powerPointType
    )
  ) {
    submitButtonText = "Regenerate Word Doc";
  } else if (props.pdfUrl === "") {
    submitButtonText = "Create PowerPoint";
  } else {
    submitButtonText = "Regenerate PowerPoint";
  }

  return (
    <Container>
      <Col>
        <Row>
          <Form className="form" style={{ marginBottom: 20, marginTop: 0 }}>
            <Form.Select
              name="powerPointType"
              size="sm"
              aria-label="Default select example"
              style={{ marginTop: 0, marginBottom: 0, fontSize: 14 }}
              onChange={(e) => {
                if (e.target.value === "") {
                  props.handleStudioFormChange({
                    [e.target.name]: "",
                  });
                } else {
                  const productJson = JSON.parse(e.target.value);
                  props.handleStudioFormChange({
                    [e.target.name]: productJson.tag,
                    customerOrgID: productJson.customer_org_id,
                  });
                }
                setShowFormErrors(false);
              }}
            >
              <option value="">Select</option>
              {props.userProducts.map((product) => (
                <option value={JSON.stringify(product)}>{product.name}</option>
              ))}
            </Form.Select>
            {showFormErrors && props.powerPointType === "" ? (
              <p className="error center_horizontal">
                Please select a PowerPoint type
              </p>
            ) : null}

            {pptTypeToFormElements[
              props.studioFormData.powerPointType
            ].includes("company_overview_deck_type") &&
              props.studioFormData.customerOrgID === 1 && (
                <Form.Select
                  name="companyOverviewDeckType"
                  size="sm"
                  aria-label="Default select example"
                  style={{ marginTop: 20, marginBottom: 0, fontSize: 14 }}
                  onChange={(e) => {
                    props.handleStudioFormChange({
                      [e.target.name]: e.target.value,
                    });
                    setShowFormErrors(false);
                  }}
                >
                  <option value="">Select PowerPoint Type</option>
                  <option value="fintech">Fintech</option>
                  <option value="generic">Generic</option>
                  <option value="commerce_enablement">
                    Commerce Enablement
                  </option>
                  <option value="consumer_internet">Consumer Internet</option>
                  <option value="cpg_brands">CPG/Brands</option>
                  <option value="web3">Web3</option>
                </Form.Select>
              )}

            {pptTypeToFormElements[
              props.studioFormData.powerPointType
            ].includes("competitors_search_type") &&
              props.studioFormData.customerOrgID === 1 && (
                <Form.Select
                  name="competitorsSearchType"
                  size="sm"
                  aria-label="Default select example"
                  style={{ marginTop: 20, marginBottom: 0, fontSize: 14 }}
                  onChange={(e) => {
                    props.handleStudioFormChange({
                      [e.target.name]: e.target.value,
                    });
                    setShowFormErrors(false);
                  }}
                >
                  <option value="">Select Comps Search Type</option>
                  <option value="gpt">Chat GPT</option>
                  <option value="exa">Exa.ai</option>
                </Form.Select>
              )}

            {pptTypeToFormElements[
              props.studioFormData.powerPointType
            ].includes("industry_name") && (
              <Form.Group controlId="formBasicText1">
                <Form.Label
                  style={{ marginBottom: 0, marginTop: 10 }}
                  className="demo-form-input-label"
                >
                  Industry Name
                </Form.Label>
                <Form.Control
                  name="industryName"
                  size="sm"
                  type="text"
                  onChange={(e) => {
                    props.handleStudioFormChange({
                      [e.target.name]: e.target.value,
                    });
                    setShowFormErrors(false);
                  }}
                />
                {showFormErrors &&
                props.studioFormData.companyName.length > 30 ? (
                  <p className="error center_horizontal">
                    Max length is 30 characters
                  </p>
                ) : null}
                <div className="center_horizontal">
                  <Form.Label
                    style={{ marginBottom: 0, marginTop: 10 }}
                    className="demo-form-input-label"
                  >
                    Or
                  </Form.Label>
                </div>
              </Form.Group>
            )}

            {pptTypeToFormElements[
              props.studioFormData.powerPointType
            ].includes("company_name") && (
              <Form.Group controlId="formBasicText1">
                <Form.Label
                  style={{ marginBottom: 0, marginTop: 10 }}
                  className="demo-form-input-label"
                >
                  Company Name
                </Form.Label>
                <Form.Control
                  name="companyName"
                  size="sm"
                  type="text"
                  placeholder="Slice"
                  onChange={(e) => {
                    props.handleStudioFormChange({
                      [e.target.name]: e.target.value,
                    });
                    setShowFormErrors(false);
                  }}
                />
                {showFormErrors && props.studioFormData.companyName === "" ? (
                  <p className="error center_horizontal">
                    This field is required
                  </p>
                ) : null}
                {showFormErrors &&
                props.studioFormData.companyName.length > 30 ? (
                  <p className="error center_horizontal">
                    Max length is 30 characters
                  </p>
                ) : null}
              </Form.Group>
            )}

            {pptTypeToFormElements[
              props.studioFormData.powerPointType
            ].includes("industry_picker") && (
              <Form.Select
                name="industryPicker"
                size="sm"
                aria-label="Default select example"
                style={{ marginTop: 20, marginBottom: 0, fontSize: 14 }}
                onChange={(e) => {
                  props.handleStudioFormChange({
                    industryName: e.target.value,
                  });
                  setShowFormErrors(false);
                }}
              >
                <option value="">Select Industry</option>
                <option value="life_health">Life & Health Insurance</option>
                <option value="property_casualty">
                  Property & Casualty Insurance
                </option>
              </Form.Select>
            )}

            {pptTypeToFormElements[
              props.studioFormData.powerPointType
            ].includes("crunchbase_id") && (
              <Form.Group controlId="formBasicText1">
                <Form.Label
                  style={{ marginBottom: 0, marginTop: 10 }}
                  className="demo-form-input-label"
                >
                  Crunchbase ID
                </Form.Label>
                <p style={{ fontSize: "12px" }}>
                  Last word in the Crunchbase page url
                </p>
                <Form.Control
                  name="crunchbaseID"
                  size="sm"
                  type="text"
                  placeholder="slicepay"
                  onChange={(e) => {
                    props.handleStudioFormChange({
                      [e.target.name]: e.target.value,
                    });
                    setShowFormErrors(false);
                  }}
                />
                {showFormErrors && props.studioFormData.companyName === "" ? (
                  <p className="error center_horizontal">
                    This field is required
                  </p>
                ) : null}
                {showFormErrors &&
                props.studioFormData.companyName.length > 30 ? (
                  <p className="error center_horizontal">
                    Max length is 30 characters
                  </p>
                ) : null}
              </Form.Group>
            )}

            {pptTypeToFormElements[
              props.studioFormData.powerPointType
            ].includes("google_search_query") && (
              <Form.Group controlId="formBasicText1">
                <Form.Label
                  style={{ marginBottom: 0, marginTop: 10 }}
                  className="demo-form-input-label"
                >
                  Google Search Query
                </Form.Label>
                <p style={{ fontSize: "12px" }}>
                  Used for the target company only
                </p>
                <Form.Control
                  name="googleSearchQuery"
                  size="sm"
                  type="text"
                  placeholder="Slice payments startup"
                  onChange={(e) => {
                    props.handleStudioFormChange({
                      [e.target.name]: e.target.value,
                    });
                    setShowFormErrors(false);
                  }}
                />
                {showFormErrors &&
                props.studioFormData.companyName.length > 30 ? (
                  <p className="error center_horizontal">
                    Max length is 30 characters
                  </p>
                ) : null}
              </Form.Group>
            )}

            {pptTypeToFormElements[
              props.studioFormData.powerPointType
            ].includes("competitive_landscape_query") && (
              <Form.Group controlId="formBasicText1">
                <Form.Label
                  style={{ marginBottom: 0, marginTop: 10 }}
                  className="demo-form-input-label"
                >
                  Competitors Prompt
                </Form.Label>
                {props.studioFormData.competitorsSearchType !== "exa" && (
                  <p style={{ fontSize: "12px" }}>Optional</p>
                )}
                <Form.Control
                  as="textarea"
                  name="competitiveLandscapeQuery"
                  size="sm"
                  type="text"
                  rows={2}
                  placeholder={
                    props.studioFormData.competitorsSearchType === "exa"
                      ? "Indian payment startups similar to Slice"
                      : "Only include Indian payment companies"
                  }
                  onChange={(e) => {
                    props.handleStudioFormChange({
                      [e.target.name]: e.target.value,
                    });
                    setShowFormErrors(false);
                  }}
                />
                {showFormErrors &&
                props.studioFormData.companyName.length > 30 ? (
                  <p className="error center_horizontal">
                    Max length is 30 characters
                  </p>
                ) : null}
              </Form.Group>
            )}

            {pptTypeToFormElements[
              props.studioFormData.powerPointType
            ].includes("founder_names_string") && (
              <Form.Group controlId="formBasicText1">
                <Form.Label
                  style={{ marginBottom: 0, marginTop: 10 }}
                  className="demo-form-input-label"
                >
                  Founder Names
                </Form.Label>
                {props.studioFormData.competitorsSearchType !== "exa" && (
                  <p style={{ fontSize: "12px" }}>Optional</p>
                )}
                <Form.Control
                  as="textarea"
                  name="founderNamesString"
                  size="sm"
                  type="text"
                  rows={2}
                  placeholder="Deepak Malhotra, Rajan Bajaj"
                  onChange={(e) => {
                    props.handleStudioFormChange({
                      [e.target.name]: e.target.value,
                    });
                    setShowFormErrors(false);
                  }}
                />
              </Form.Group>
            )}

            {pptTypeToFormElements[
              props.studioFormData.powerPointType
            ].includes("leadership_team_url") &&
              props.studioFormData.customerOrgID === 6 && (
                <Form.Group controlId="formBasicText1">
                  <Form.Label
                    style={{ marginBottom: 0, marginTop: 10 }}
                    className="demo-form-input-label"
                  >
                    Leadership Team Url
                  </Form.Label>
                  <Form.Control
                    name="leadershipTeamUrl"
                    size="sm"
                    type="text"
                    placeholder="https://www.sample_url.com"
                    onChange={(e) => {
                      props.handleStudioFormChange({
                        [e.target.name]: e.target.value,
                      });
                      setShowFormErrors(false);
                    }}
                  />
                </Form.Group>
              )}

            {pptTypeToFormElements[
              props.studioFormData.powerPointType
            ].includes("pdf_type") && (
              <Form.Select
                name="pdfType"
                size="sm"
                aria-label="Default select example"
                style={{ marginTop: 20, marginBottom: 0, fontSize: 14 }}
                onChange={(e) => {
                  props.handleStudioFormChange({
                    [e.target.name]: e.target.value,
                  });
                  setShowFormErrors(false);
                }}
              >
                <option value="">Select PDF Type</option>
                <option value="lease">Lease</option>
                <option value="loan">Loan</option>
              </Form.Select>
            )}

            {pptTypeToFormElements[
              props.studioFormData.powerPointType
            ].includes("pdf_parser") && (
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label
                  size="sm"
                  className="center_horizontal demo-form-input-label"
                  style={{ marginBottom: 10, marginTop: 20 }}
                >
                  Upload PDFs
                </Form.Label>
                <div>
                  <Form.Control
                    key={inputKey}
                    className="center_horizontal"
                    size="sm"
                    type="file"
                    multiple
                    onChange={(evt) => {
                      if (evt.target.files.length > 0) {
                        const newFiles = Array.from(evt.target.files);
                        props.setUploadedFiles((prevArray) => [
                          ...prevArray,
                          ...newFiles,
                        ]);

                        const newUrls = newFiles.map((file) =>
                          URL.createObjectURL(file)
                        );
                        props.setUploadedFileUrls((prevArray) => [
                          ...prevArray,
                          ...newUrls,
                        ]);

                        const newParams = new Array(newFiles.length).fill("");
                        props.setExtraParams((prevState) => [
                          ...prevState,
                          ...newParams,
                        ]);
                      } else {
                        props.setUploadedFiles([]);
                        props.setUploadedFileUrls([]);
                        props.setPptOptions([]);
                        props.setExtraParams([]);
                      }
                    }}
                  />
                  {props.uploadedFiles.map((file, index) => (
                    <div>
                      <span
                        style={{
                          display: "block",
                          color: "var(--color-teal-600)",
                          marginTop: "20px",
                          width: "220px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {file.name}
                      </span>
                      {/* <span
                        style={{
                          display: "block",
                          color: "black",
                          width: "220px",
                          fontSize: "14px",
                        }}
                      >
                        Optional: page ranges to exclude
                      </span>
                      <Form.Control
                        name="pageRange"
                        size="sm"
                        type="text"
                        placeholder="Ex: 5-10, 30-50, 65"
                        onChange={(e) => {
                          props.setExtraParams((prevState) =>
                            prevState.map((el, i) =>
                              i === index ? e.target.value : el
                            )
                          );
                        }}
                      /> */}
                    </div>
                  ))}
                  {props.uploadedFiles.length !== 0 && (
                    <div className="center_horizontal">
                      <Button
                        size="sm"
                        style={{ marginTop: 10 }}
                        variant="secondary"
                        type="button"
                        onClick={() => {
                          props.setUploadedFiles([]);
                          props.setUploadedFileUrls([]);
                          props.setPptOptions([]);
                          props.setExtraParams([]);
                          setInputKey(Date.now()); // Resets the key, causing re-render
                        }}
                      >
                        Clear Files
                      </Button>
                    </div>
                  )}
                </div>
              </Form.Group>
            )}

            {pptTypeToFormElements[
              props.studioFormData.powerPointType
            ].includes("model_uploader") && (
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label
                  size="sm"
                  className="center_horizontal demo-form-input-label"
                  style={{ marginBottom: 10, marginTop: 20 }}
                >
                  Upload Model
                </Form.Label>
                <div>
                  <Form.Control
                    key={inputKey}
                    className="center_horizontal"
                    size="sm"
                    type="file"
                    onChange={(evt) => {
                      if (evt.target.files.length > 0) {
                        const newFiles = Array.from(evt.target.files);
                        props.setUploadedFiles(newFiles);
                        const newUrls = newFiles.map((file) =>
                          URL.createObjectURL(file)
                        );
                        props.setUploadedFileUrls(newUrls);
                        const newParams = new Array(newFiles.length).fill("");
                        props.setExtraParams(newParams);
                        props.setPptOptions([]);
                      } else {
                        props.setUploadedFiles([]);
                        props.setUploadedFileUrls([]);
                        props.setPptOptions([]);
                        props.setExtraParams([]);
                      }

                      // Revoke old object URLs to avoid memory leaks
                      props.setUploadedFileUrls((prevUrls) => {
                        prevUrls.forEach(URL.revokeObjectURL);
                        return [];
                      });
                    }}
                  />
                </div>
              </Form.Group>
            )}

            {pptTypeToFormElements[
              props.studioFormData.powerPointType
            ].includes("cash_amount") && (
              <Form.Group controlId="formBasicText1">
                <Form.Label
                  style={{ marginBottom: 0, marginTop: 10 }}
                  className="demo-form-input-label"
                >
                  Most Recent Actual Cash Balance
                </Form.Label>
                <Form.Control
                  name="cashAmount"
                  size="sm"
                  type="text"
                  placeholder="$6,500,000"
                  onChange={(e) => {
                    props.handleStudioFormChange({
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </Form.Group>
            )}

            {pptTypeToFormElements[
              props.studioFormData.powerPointType
            ].includes("actual_time_period") && (
              <Form.Group controlId="formBasicText1">
                <Form.Label
                  style={{ marginBottom: 0, marginTop: 10 }}
                  className="demo-form-input-label"
                >
                  Most Recent Actual Time Period
                </Form.Label>
                <Form.Control
                  name="actualTimePeriod"
                  size="sm"
                  type="text"
                  placeholder="ex. Q3 2023 or 2023"
                  onChange={(e) => {
                    props.handleStudioFormChange({
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </Form.Group>
            )}

            {pptTypeToFormElements[
              props.studioFormData.powerPointType
            ].includes("equity_amount") && (
              <Form.Group controlId="formBasicText1">
                <Form.Label
                  style={{ marginBottom: 0, marginTop: 10 }}
                  className="demo-form-input-label"
                >
                  Equity Funding Amount
                </Form.Label>
                <Form.Control
                  name="equityFundingAmount"
                  size="sm"
                  type="text"
                  placeholder="$1,000,000"
                  onChange={(e) => {
                    props.handleStudioFormChange({
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </Form.Group>
            )}

            {pptTypeToFormElements[
              props.studioFormData.powerPointType
            ].includes("debt_amount") && (
              <Form.Group controlId="formBasicText1">
                <Form.Label
                  style={{ marginBottom: 0, marginTop: 10 }}
                  className="demo-form-input-label"
                >
                  Debt Funding Amount
                </Form.Label>
                <Form.Control
                  name="debtFundingAmount"
                  size="sm"
                  type="text"
                  placeholder="$1,000,000"
                  onChange={(e) => {
                    props.handleStudioFormChange({
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </Form.Group>
            )}

            {pptTypeToFormElements[
              props.studioFormData.powerPointType
            ].includes("funding_time_period") && (
              <Form.Group controlId="formBasicText1">
                <Form.Label
                  style={{ marginBottom: 0, marginTop: 10 }}
                  className="demo-form-input-label"
                >
                  Expected Funding Time Period
                </Form.Label>
                <Form.Control
                  name="fundingTimePeriod"
                  size="sm"
                  type="text"
                  placeholder="ex. Q3 2023 or 2023"
                  onChange={(e) => {
                    props.handleStudioFormChange({
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
              </Form.Group>
            )}

            {pptTypeToFormElements[
              props.studioFormData.powerPointType
            ].includes("json_file_uploader") && (
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label
                  size="sm"
                  className="center_horizontal demo-form-input-label"
                  style={{ marginBottom: 10, marginTop: 20 }}
                >
                  Upload JSON File
                </Form.Label>
                <div>
                  <Form.Control
                    key={inputKey}
                    className="center_horizontal"
                    size="sm"
                    type="file"
                    onChange={(evt) => {
                      const file = evt.target.files[0];
                      if (file) {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                          try {
                            const json = JSON.parse(e.target.result);
                            console.log(json);
                            props.setPptOptions(json);
                            console.log(props.pptOptions);
                          } catch (error) {
                            alert("Invalid JSON file");
                          }
                        };
                        reader.readAsText(file);
                      }
                    }}
                  />
                </div>
              </Form.Group>
            )}

            <Button
              size="sm"
              style={{
                marginTop: 20,
                marginBottom: 10,
                backgroundColor: "var(--color-teal-400)",
                border: "none",
              }}
              variant="primary"
              type="button"
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              {submitButtonText}
            </Button>
          </Form>

          {showBackendErrorMessage && (
            <p className="center_horizontal" style={{ color: "red" }}>
              An error occurred
            </p>
          )}

          {isSubmitting && (
            <div className="center_horizontal" style={{ marginTop: 0 }}>
              <l-tail-chase size="30" speed="1.5" color="black" />
            </div>
          )}
        </Row>
      </Col>
    </Container>
  );
}

export default StudioForm;
