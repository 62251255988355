import Container from "react-bootstrap/Container";
import { Col, Row, Button, Form } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import TopNavbar from "../navbars/TopNavbar.js";
import React, { useState, useEffect } from "react";
import { getCurrentUser } from "aws-amplify/auth";
import { signOut } from "aws-amplify/auth";
import ImageGrid from "./ImageGrid";
import SandboxForm from "./SandboxForm";
import * as JSZip from "jszip";
import "react-resizable/css/styles.css";
import { fetchAuthSession } from "aws-amplify/auth";

function SandboxPage() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [assetUrlsToChartData, setAssetUrlsToChartData] = useState({});
  const [companyLogosTxtFile, setCompanyLogosTxtFile] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [formData, setFormData] = useState({
    assetType: "",
    companyName: "",
    executiveName: "",
    iconName: "",
    chartName: "",
    websiteUrl: "",
    headshotShape: "",
    iconHexCode: "#000000",
    chartCategoryNames: "",
    chartValues: "",
    chartHexCode: "#12385E",
  });
  const [images, setImages] = useState([]);
  const [imageGridBox, setImageGridBox] = useState({
    x: 90,
    y: 95,
    width: 450,
    height: 350,
    numRows: 3,
    numCols: 3,
    size: 100,
    id: 5,
    slideDimensions: "standard",
    ref: React.createRef(),
  });

  useEffect(() => {
    const isUserLoggedIn = async () => {
      try {
        let user = await getCurrentUser();
        let email = user.signInDetails.loginId;
        setUserEmail(email);
        const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      } catch (error) {
        await signOut();
      }
    };
    isUserLoggedIn();
  });

  const handleFormChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const isSandboxFormValid = () => {
    const isAssetTypeSelected = formData.assetType !== "";
    const isCompanyLogoFormValid =
      formData.assetType !== "company_logo" || formData.companyName !== "";
    const isExecutiveHeadshotFormValid =
      formData.assetType !== "executive_headshot" ||
      (formData.executiveName !== "" && formData.companyName !== "");
    const isIconFormValid =
      formData.assetType !== "icon" || formData.iconName !== "";
    return (
      isAssetTypeSelected &&
      isCompanyLogoFormValid &&
      isExecutiveHeadshotFormValid &&
      isIconFormValid
    );
  };

  const handleFormSubmit = async () => {
    if (isSandboxFormValid()) {
      setIsSubmitting(true);
      await fetchImage();
      setIsSubmitting(false);
    } else {
      alert("At least one required field is empty");
    }
  };

  const fetchImage = async () => {
    const imageTypeParam = "image_type=".concat(formData.assetType);
    const companyNameParam = "&company_name=".concat(
      formData.companyName.replace("&", "and")
    );
    const websiteUrlParam = "&company_website_url=".concat(formData.websiteUrl);
    const iconNameParam = "&icon_name=".concat(formData.iconName);
    const executiveNameParam = "&executive_name=".concat(
      formData.executiveName
    );
    const headshotShapeParam = "&headshot_shape=".concat(
      formData.headshotShape
    );
    const iconHexCodeParam = "&icon_hex_code=".concat(
      formData.iconHexCode.replaceAll("#", "")
    );
    const chartHexCodeParam = "&chart_hex_code=".concat(
      formData.chartHexCode.replaceAll("#", "")
    );
    const chartCategoryNamesParam = "&chart_category_names=".concat(
      formData.chartCategoryNames
    );
    const chartValuesParam = "&chart_values=".concat(formData.chartValues);
    const emailParam = "&email=".concat(userEmail);
    const apiEndpoint = "https://lucite-backend-dev.com/get_image?".concat(
      imageTypeParam,
      companyNameParam,
      websiteUrlParam,
      iconNameParam,
      executiveNameParam,
      headshotShapeParam,
      iconHexCodeParam,
      chartHexCodeParam,
      chartCategoryNamesParam,
      chartValuesParam,
      emailParam
    );
    const formFiles = new FormData();
    if (companyLogosTxtFile !== "") {
      formFiles.append("companyLogosTxtFile", companyLogosTxtFile);
    }
    console.log(apiEndpoint);
    await fetch(apiEndpoint, {
      method: "POST",
      body: formFiles,
    })
      .then((response) => response.blob())
      .then(async (blob) => {
        if (blob.size > 1000) {
          const zip = new JSZip();
          let i = 0;
          let promises = [];
          zip.loadAsync(blob).then(function (zip) {
            Object.keys(zip.files).forEach(function (filename) {
              let promise = zip
                .file(filename)
                .async("blob")
                .then(function (blob) {
                  let url = window.URL.createObjectURL(new Blob([blob]));
                  setImages((images) => [
                    ...images,
                    {
                      assetName: filename,
                      url,
                    },
                  ]);
                  setImageGridBox((prev) => ({
                    ...prev,
                    numCols: Math.ceil(
                      Math.sqrt(Object.keys(zip.files).length + images.length)
                    ),
                    numRows: Math.ceil(
                      Math.sqrt(Object.keys(zip.files).length + images.length)
                    ),
                  }));

                  if (formData.assetType === "bar_chart") {
                    setAssetUrlsToChartData({
                      ...assetUrlsToChartData,
                      [url]: {
                        chartCategoryNames: formData.chartCategoryNames,
                        chartValues: formData.chartValues,
                        chartHexCode: formData.chartHexCode.replaceAll("#", ""),
                      },
                    });
                  }
                  if (filename === "failed_image_fetch_file.txt") {
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = "Failed Fetch Companies.txt";
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                  }
                  i++;
                });
              promises.push(promise);
            });
          });
        } else {
          alert("An error occurred while fetching your image");
        }
      })
      .catch(async function (error) {
        console.log(error);
        setIsSubmitting(false);
        alert("Unable to fetch image at this time");
      });
  };

  const generatePowerpoint = async () => {
    const zip = new JSZip();

    // Fetch each file and add to the zip
    const filePromises = images.map(async (image, index) => {
      const response = await fetch(image.url);
      const blob = await response.blob();
      zip.file(`sandbox_image_${index}.png`, blob);
    });

    await Promise.all(filePromises);

    // Generate zip blob
    const zipBlob = await zip.generateAsync({ type: "blob" });

    // Send the zip blob in a POST request
    const formData = new FormData();
    formData.append("zipFile", zipBlob, "files.zip");

    const jsonBlob = new Blob([JSON.stringify(imageGridBox)], {
      type: "application/json",
    });
    formData.append("jsonData", jsonBlob);

    const emailParam = "email=".concat(userEmail);
    const apiUrl =
      "https://lucite-backend-dev.com/generate_powerpoint_sandbox?".concat(
        emailParam
      );

    await fetch(apiUrl, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.blob())
      .then(async (blob) => {
        if (blob.size > 1000) {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.download = "Sandbox.pptx";
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      });
  };

  const onDragStop = (id, e, d) => {
    setImageGridBox({ ...imageGridBox, x: d.x, y: d.y });
  };

  const onResizeStop = (id, e, direction, ref, delta, position) => {
    setImageGridBox({
      ...imageGridBox,
      width: ref.offsetWidth,
      height: ref.offsetHeight,
      ...position,
    });
  };

  const sortImagesAlphabetically = () => {
    const imagesCopy = [...images];
    const regex = /_\d+/; // remove _{some number} patters since this would just be the image number
    const sortedImages = imagesCopy.sort((a, b) =>
      a.assetName.toLowerCase().replace(regex, "") >=
      b.assetName.toLowerCase().replace(regex, "")
        ? 1
        : -1
    );
    setImages(sortedImages);
  };

  const gridHeight = 540; // In pixels
  const gridWidth = imageGridBox.slideDimensions === "standard" ? 720 : 960;
  const cellSize = 72; // Pixels per inch (96) * 0.75
  const numVerticalTicks = gridHeight / cellSize;
  const numHorizontalTicks = gridWidth / cellSize;

  // Generate tick marks
  const horizontalTicks = [];
  const verticalTicks = [];
  for (let i = 1; i < numHorizontalTicks; i++) {
    horizontalTicks.push(
      <div>
        <div
          className="horizontal-tick"
          style={{ left: `${i * cellSize}px` }}
          key={`h-tick-${i}`}
        />
        <div
          className="tick-label horizontal-tick-label"
          style={{ left: `${i * cellSize}px` }}
          key={`h-label-${i}`}
        >
          {i}
        </div>
      </div>
    );
  }
  for (let i = 1; i < numVerticalTicks; i++) {
    verticalTicks.push(
      <div>
        <div
          className="vertical-tick"
          style={{ top: `${i * cellSize}px` }}
          key={`v-tick-${i}`}
        />
        <div
          className="tick-label vertical-tick-label"
          style={{ top: `${i * cellSize}px` }}
          key={`v-label-${i}`}
        >
          {i}
        </div>
      </div>
    );
  }

  const gridStyle = {
    display: "grid",
    position: "fixed",
    left: "60%",
    transform: "translateX(-50%)",
    top: "132px",
    gap: 0 /* Space between grid items */,
    padding: 0,
    border: "1px solid #ccc",
    width: `${gridWidth}px`,
    height: "540px" /* Adjust the height as needed */,
    backgroundColor: "rgb(255, 255, 255)",
  };

  return (
    <Container className="sandbox-container">
      <Row>
        <Col
          style={{
            position: "absolute",
            zIndex: 15,
          }}
        >
          <TopNavbar />
          <Navbar expand="lg" variant="light" className="bg-light left-navbar">
            <Container>
              <div
                style={{
                  flex_direction: "column",
                  height: "95vh",
                  overflowY: "auto",
                }}
              >
                <SandboxForm
                  style={{ marginTop: "0px" }}
                  formData={formData}
                  handleFormChange={handleFormChange}
                  handleFormSubmit={handleFormSubmit}
                  isSubmitting={isSubmitting}
                  imageGridBox={imageGridBox}
                  setImageGridBox={setImageGridBox}
                  sortImagesAlphabetically={sortImagesAlphabetically}
                  setCompanyLogosTxtFile={setCompanyLogosTxtFile}
                  images={images}
                />
              </div>
            </Container>
          </Navbar>
        </Col>
      </Row>
      <div>
        <Col>
          <Row
            style={{
              top: "90px",
              left: "60%",
              width: "720px",
              transform: "translateX(-50%)",
              position: "fixed",
            }}
          >
            <div style={{ width: "200px", marginTop: "5px" }}>
              <Form.Select
                name="slideDimensions"
                size="sm"
                aria-label="Default select example"
                style={{ marginTop: 0, marginBottom: 0, fontSize: 14 }}
                onChange={(e) =>
                  setImageGridBox((prev) => ({
                    ...prev,
                    slideDimensions: e.target.value,
                  }))
                }
              >
                <option value="standard">Standard (4:3)</option>
                <option value="wide">Wide (16:9)</option>
              </Form.Select>
            </div>
            <div style={{ width: "200px", top: "90px" }}>
              <Button
                // className="sandbox-generate-powerpoint-button"
                size="sm"
                variant="primary"
                type="button"
                onClick={() => generatePowerpoint()}
              >
                Generate PowerPoint
              </Button>
            </div>
          </Row>

          <div style={gridStyle} tabIndex={0}>
            {horizontalTicks}
            {verticalTicks}
            <ImageGrid
              key={imageGridBox.id}
              box={imageGridBox}
              bounds="parent"
              nodeRef={imageGridBox.ref}
              onDragStop={onDragStop}
              onResizeStop={onResizeStop}
              images={images}
              setImages={setImages}
            />
          </div>
        </Col>
      </div>
    </Container>
  );
}

export default SandboxPage;
