import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";

function CompetitorBucketsForm({ pptContent, setPptContent }) {
  const removeCompany = (bucketName, companyName) => {
    setPptContent((prevState) => ({
      ...prevState, // Maintain the structure of the top-level state
      competitive_landscape: {
        ...prevState.competitive_landscape, // Maintain all other properties of competitive_landscape
        content: prevState.competitive_landscape.content.map((bucket) =>
          bucket.bucket_title === bucketName
            ? {
                ...bucket,
                companies: bucket.companies.filter(
                  (comp) => comp !== companyName // Remove the company from the list
                ),
              }
            : bucket
        ),
      },
    }));
  };

  const addCompany = (bucketName, companyName) => {
    setPptContent((prevState) => ({
      ...prevState, // Maintain the structure of the top-level state
      competitive_landscape: {
        ...prevState.competitive_landscape, // Maintain all other properties of competitive_landscape
        content: prevState.competitive_landscape.content.map((bucket) =>
          bucket.bucket_title === bucketName
            ? {
                ...bucket,
                companies: [...bucket.companies, companyName], // Add new company to the list
              }
            : bucket
        ),
      },
    }));
  };

  const removeBucket = (bucketNameToRemove) => {
    setPptContent((prevState) => ({
      ...prevState, // Maintain the structure of the top-level state
      competitive_landscape: {
        ...prevState.competitive_landscape, // Maintain all other properties of competitive_landscape
        content: prevState.competitive_landscape.content.filter(
          (bucket) => bucket.bucket_title !== bucketNameToRemove
        ), // Remove the bucket with the specified name
      },
    }));
  };

  const addBucket = (bucketName) => {
    if (pptContent.competitive_landscape.content.length === 4) {
      alert("Cannot add more than 4 buckets");
    } else {
      const newBucket = {
        bucket_title: bucketName,
        companies: [],
      };
      setPptContent((prevState) => ({
        ...prevState, // Spread and keep the top-level state properties
        competitive_landscape: {
          // Explicitly update this nested object
          ...prevState.competitive_landscape, // Spread existing properties of the nested object
          content: [
            ...prevState.competitive_landscape.content, // Include existing content
            newBucket, // Append the new bucket
          ],
        },
      }));
    }
  };

  const updateBucketName = (oldName, newName) => {
    setPptContent((prevState) => ({
      ...prevState, // Maintain the structure of the top-level state
      competitive_landscape: {
        ...prevState.competitive_landscape, // Maintain all other properties of competitive_landscape
        content: prevState.competitive_landscape.content.map((bucket) =>
          bucket.bucket_title === oldName
            ? { ...bucket, bucket_title: newName } // Only update bucket_title if it matches oldName
            : bucket
        ),
      },
    }));
  };

  const updateCompanyName = (bucketName, oldCompanyName, newCompanyName) => {
    setPptContent((prevState) => ({
      ...prevState, // Spread the top level of prevState to maintain all other state
      competitive_landscape: {
        ...prevState.competitive_landscape, // Spread competitive_landscape to maintain all other properties
        content: prevState.competitive_landscape.content.map((bucket) =>
          bucket.bucket_title === bucketName
            ? {
                ...bucket,
                companies: bucket.companies.map((company) =>
                  company === oldCompanyName ? newCompanyName : company
                ),
              }
            : bucket
        ),
      },
    }));
  };

  console.log(pptContent);

  return (
    <div className="editable-rows-form" style={{ marginTop: "40px" }}>
      {pptContent.competitive_landscape.content.map((bucket) => (
        <div key={bucket.bucket_title}>
          <div className="competitor-container">
            <InlineEdit
              key={bucket.bucket_title}
              text={bucket.bucket_title}
              onTextChange={(newName) =>
                updateBucketName(bucket.bucket_title, newName)
              }
              isBucketName={true}
            />
            <button
              className="competitor-add-remove-button"
              style={{ backgroundColor: "red" }}
              onClick={() => removeBucket(bucket.bucket_title)}
            >
              -
            </button>
          </div>
          {bucket.companies.map((companyName) => (
            <div key={companyName} className="competitor-container">
              <InlineEdit
                key={companyName}
                text={companyName}
                onTextChange={(newName) =>
                  updateCompanyName(bucket.bucket_title, companyName, newName)
                }
                isBucketName={false}
              />
              <button
                className="competitor-add-remove-button"
                style={{ backgroundColor: "red" }}
                onClick={() => removeCompany(bucket.bucket_title, companyName)}
              >
                -
              </button>
            </div>
          ))}
          <AddCompanyForm
            style={{ marginTop: 0 }}
            bucketName={bucket.bucket_title}
            addCompany={addCompany}
          />
        </div>
      ))}
      <AddBucketForm addBucket={addBucket} />
    </div>
  );
}

const AddCompanyForm = ({ bucketName, addCompany }) => {
  const [newCompanyName, setNewCompanyName] = useState("");

  const handleSubmit = (e) => {
    if (newCompanyName !== "") {
      e.preventDefault();
      addCompany(bucketName, newCompanyName);
      setNewCompanyName("");
    }
  };

  return (
    <div
      className="add-competitor-form"
      style={{ marginLeft: 0, marginBottom: 40, marginTop: 5 }}
    >
      <Form.Control
        className="add-competitor-form-input"
        size="sm"
        type="text"
        placeholder="Company Name"
        value={newCompanyName}
        onChange={(e) => setNewCompanyName(e.target.value)}
      />
      <Button
        size="sm"
        variant="primary"
        type="button"
        className="competitor-add-remove-button"
        onClick={handleSubmit}
        style={{ backgroundColor: "green" }}
      >
        +
      </Button>
    </div>
  );
};

const AddBucketForm = ({ addBucket }) => {
  const [newBucketName, setNewBucketName] = useState("");

  const handleSubmit = (e) => {
    if (newBucketName !== "") {
      e.preventDefault();
      addBucket(newBucketName);
      setNewBucketName("");
    }
  };

  return (
    <div
      className="add-competitor-form"
      style={{ marginLeft: 0, marginTop: 30 }}
    >
      <Form.Control
        className="add-competitor-form-input"
        size="sm"
        type="text"
        placeholder="Add Bucket"
        value={newBucketName}
        onChange={(e) => setNewBucketName(e.target.value)}
      />
      <Button
        size="sm"
        variant="primary"
        type="button"
        className="competitor-add-remove-button"
        onClick={handleSubmit}
        style={{ backgroundColor: "green" }}
      >
        +
      </Button>
    </div>
  );
};

const InlineEdit = ({ text, onTextChange, isBucketName }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(text);

  const handleBlur = () => {
    setIsEditing(false);
    onTextChange(inputValue);
  };

  if (isEditing) {
    return (
      <Form.Control
        className="vc-competitor-form-row"
        size="sm"
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onBlur={handleBlur}
        autoFocus
      />
    );
  } else if (isBucketName) {
    return (
      <h6 style={{ width: "350px" }} onClick={() => setIsEditing(true)}>
        {text}
      </h6>
    );
  }
  return (
    <span style={{ width: "200px" }} onClick={() => setIsEditing(true)}>
      {text}
    </span>
  );
};

export default CompetitorBucketsForm;
