import { useState } from "react";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function DeckViewer(props) {
  const [numPages, setNumPages] = useState(3);

  function onDocumentLoadSuccess(pdf) {
    setNumPages(pdf.numPages);
  }

  function onSlideClick(slideNumber) {
    props.setCurrentSlideNumber(slideNumber);
  }

  return (
    <Container style={{ overflowY: "auto", marginBottom: "80px" }}>
      <Col>
        <Row>
          <Document
            loading={""}
            file={props.pdfUrl}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(Math.ceil(numPages / 2)), (_, rowNumber) => (
              <div
                className="center_horizontal"
                style={{
                  justifyContent: "space-evenly",
                  marginBottom: 50,
                }}
              >
                <div
                  style={{
                    cursor: "pointer",
                    boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.2)",
                  }}
                  onClick={() => onSlideClick(rowNumber * 2 + 1)}
                >
                  <Page
                    width={350}
                    loading={""}
                    key={`page_${rowNumber * 2 + 1}`}
                    pageNumber={rowNumber * 2 + 1}
                  />
                </div>
                {rowNumber * 2 + 2 <= numPages && (
                  <div
                    style={{
                      cursor: "pointer",
                      boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.2)",
                    }}
                    onClick={() => onSlideClick(rowNumber * 2 + 2)}
                  >
                    <Page
                      width={350}
                      loading={""}
                      key={`page_${rowNumber * 2 + 2}`}
                      pageNumber={rowNumber * 2 + 2}
                    />
                  </div>
                )}
              </div>
            ))}
          </Document>
        </Row>
      </Col>
    </Container>
  );
}

export default DeckViewer;
