import React, { useState, useEffect } from "react";
import { InputGroup, FormControl } from "react-bootstrap";

function IndustryFinancialsForm({ pptContent, setPptContent, slideNumber }) {
  const firstBarChartSlide = 4;
  const numIndustries = pptContent.length;
  const dataField =
    slideNumber <= firstBarChartSlide + numIndustries
      ? "includeInNTMCompsSlides"
      : "includeInLTMCompsSlides";
  const isIndustrySlide =
    slideNumber === firstBarChartSlide ||
    slideNumber === firstBarChartSlide + numIndustries + 1;
  console.log(dataField);
  let industryName;
  let companiesList;
  let items;
  if (!isIndustrySlide) {
    const industryIndex =
      slideNumber < firstBarChartSlide + numIndustries + 1
        ? slideNumber - firstBarChartSlide - 1
        : slideNumber - firstBarChartSlide - numIndustries - 2;
    console.log(industryIndex);
    console.log(slideNumber);
    industryName = pptContent[industryIndex].industryName;
    items = pptContent
      .filter((industry) => industry.industryName === industryName)[0]
      .companiesList.map((company) => {
        return {
          name: company.companyName,
          [dataField]: company[dataField],
        };
      });
    companiesList = pptContent.filter(
      (industry) => industry.industryName === industryName
    )[0].companiesList;
  } else {
    items = pptContent.map((industry) => {
      return {
        name: industry.industryName,
        [dataField]: industry[dataField],
      };
    });
  }
  console.log(items);

  const handleCheckboxChange = (name, isChecked) => {
    let updatedPptContent;
    if (!isIndustrySlide) {
      updatedPptContent = pptContent.map((industry) => {
        if (industry.industryName === industryName) {
          return {
            ...industry,
            companiesList: companiesList.map((company) => {
              if (company.companyName === name) {
                return { ...company, [dataField]: isChecked };
              }
              return company;
            }),
          };
        }
        return industry;
      });
    } else {
      updatedPptContent = pptContent.map((industry) => {
        if (industry.industryName === name) {
          return {
            ...industry,
            [dataField]: isChecked,
          };
        }
        return industry;
      });
    }

    setPptContent(updatedPptContent);
  };

  return (
    <div className="checkmark-form-container">
      {items.map((item) => (
        <InputGroup style={{ width: "250px" }}>
          <InputGroup.Checkbox
            checked={item[dataField]}
            onChange={(e) => handleCheckboxChange(item.name, e.target.checked)}
          />
          <FormControl
            aria-label={item.name}
            defaultValue={item.name}
            disabled
          />
        </InputGroup>
      ))}
    </div>
  );
}

export default IndustryFinancialsForm;
