import { Amplify } from "aws-amplify";
import {
  Authenticator,
  Flex,
  Grid,
  Image,
  useAuthenticator,
  TextField,
  View,
} from "@aws-amplify/ui-react";
import awsExports from "./aws-exports";
import React from "react";

import { Route, Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";

import DemoPage from "./components/demo/DemoPage";
import StudioPage from "./components/studio/StudioPage";
import SandboxPage from "./components/sandbox/SandboxPage";
import logo from "./components/auth_logo.png";

Amplify.configure(awsExports);

const components = {
  SignUp: {
    FormFields() {
      const { validationErrors } = useAuthenticator();
      return (
        <>
          <Authenticator.SignUp.FormFields />
          <TextField
            placeholder="Enter your Company Name"
            label="Company Name"
            errorMessage={validationErrors.acknowledgement}
            hasError={!!validationErrors.acknowledgement}
            isRequired={true}
            name="custom:company_name"
          />
        </>
      );
    },
  },
};

const formFields = {
  signUp: {
    given_name: {
      placeholder: "Enter your First Name",
      isRequired: true,
      label: "First Name:",
    },
    family_name: {
      placeholder: "Enter your Last Name",
      isRequired: true,
      label: "Last Name:",
    },
  },
};

function LoginPage() {
  return (
    <Grid templateColumns={{ base: "1fr 0", medium: "1fr 1fr" }}>
      <Flex justifyContent="center">
        <Authenticator components={components} formFields={formFields}>
          <BrowserRouter>
            <Routes>
              <Route path="*" element={<DemoPage />} />
              <Route path="/toolkit" element={<SandboxPage />} />
              <Route path="/enterprise" element={<StudioPage />} />
            </Routes>
          </BrowserRouter>
        </Authenticator>
      </Flex>
      <View
        height="100vh"
        width="50vw"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage:
            "linear-gradient(rgb(49, 108, 244), rgb(101, 206, 174))",
        }}
      >
        <Image src={logo} width="70%" />
      </View>
    </Grid>
  );
}

export default LoginPage;
