import React from "react";
import { Viewer, Worker, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { highlightPlugin, Trigger } from "@react-pdf-viewer/highlight";

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";

function PDFViewer({ pdfUrl, highlightBoxes }) {
  console.log(pdfUrl);
  console.log(highlightBoxes);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const initialPageIndex = highlightBoxes[0].pageIndex;
  console.log(initialPageIndex);

  const scaledBoxes = highlightBoxes.map((box) => {
    return {
      pageIndex: box.pageIndex,
      width: box.Width * 100,
      height: box.Height * 100,
      left: box.Left * 100,
      top: box.Top * 100,
    };
  });

  // const areas = [
  //   {
  //     pageIndex: initialPageIndex,
  //     width: highlightBox.Width * 100,
  //     height: highlightBox.Height * 100,
  //     left: highlightBox.Left * 100,
  //     top: highlightBox.Top * 100,
  //   },
  // ];
  console.log(scaledBoxes);

  const renderHighlights = (props) => (
    <div>
      {scaledBoxes
        .filter((area) => area.pageIndex === props.pageIndex)
        .map((area, idx) => (
          <div
            key={idx}
            className="highlight-area"
            style={Object.assign(
              {},
              {
                background: "yellow",
                opacity: 0.4,
              },
              props.getCssProperties(area, props.rotation)
            )}
          />
        ))}
    </div>
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });

  return (
    <div style={{ height: "100vh" }}>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <Viewer
          fileUrl={pdfUrl}
          plugins={[defaultLayoutPluginInstance, highlightPluginInstance]}
          initialPage={initialPageIndex}
          // defaultScale={SpecialZoomLevel.PageWidth}
          defaultScale={1.5}
        />
      </Worker>
    </div>
  );
}

export default PDFViewer;
