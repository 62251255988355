import React, { useState } from "react";
import { PiFileMagnifyingGlass } from "react-icons/pi";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { highlightPlugin, Trigger } from "@react-pdf-viewer/highlight";
import { VscClose } from "react-icons/vsc";
import { IoMdAlert } from "react-icons/io";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import "./ExtractedFieldsViewer.css";

const PDFModal = ({ isOpen, onClose, pdfUrl, highlightBoxes }) => {
  const scaledBoxes = highlightBoxes.map((box) => {
    return {
      pageIndex: box.pageIndex,
      width: box.width * 100,
      height: box.height * 100,
      left: box.left * 100,
      top: box.top * 100,
    };
  });
  const defaultLayoutPluginInstance = defaultLayoutPlugin({});
  const highlightPluginInstance = highlightPlugin({
    renderHighlights: (props) => (
      <div>
        {scaledBoxes
          .filter((area) => area.pageIndex === props.pageIndex)
          .map((area, idx) => (
            <div
              key={idx}
              className="highlight-area"
              style={{
                ...props.getCssProperties(area, props.rotation),
                background: "yellow",
                opacity: 0.4,
              }}
            />
          ))}
      </div>
    ),
    trigger: Trigger.None,
  });

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          <VscClose />
        </button>
        <div className="pdf-viewer">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer
              fileUrl={pdfUrl}
              plugins={[defaultLayoutPluginInstance, highlightPluginInstance]}
              initialPage={highlightBoxes[0]?.pageIndex || 1}
              defaultScale={1.3}
            />
          </Worker>
        </div>
      </div>
    </div>
  );
};

const SectionComponent = ({ sectionName, fields, onIconClick }) => (
  <div className="extracted-fields-section">
    <h4>{sectionName}</h4>
    <hr />
    <table>
      <colgroup>
        <col style={{ width: "30%" }} />
        <col style={{ width: "40%" }} />
        <col style={{ width: "15%" }} />
        <col style={{ width: "15%" }} />
      </colgroup>
      <tbody>
        {fields.map((field, index) => (
          <tr key={index}>
            <td className="field-name">{field.field_name}</td>
            <td className="response">{field.response}</td>
            <td className="confidence-indicator">
              {field.high_confidence === false && (
                <>
                  <IoMdAlert size={20} />
                  <div className="tooltip">
                    This response has a lower confidence score. Consider
                    additional verification.
                  </div>
                </>
              )}
            </td>
            <td className="icon-cell">
              {"box" in field && (
                <PiFileMagnifyingGlass
                  className="field-row-icon"
                  onClick={() => onIconClick(field)}
                />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const ExtractedFieldsViewer = ({ extractedFields, files }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedField, setSelectedField] = useState(null);

  const groupedFields = extractedFields.reduce((acc, field) => {
    if (!acc[field.section]) {
      acc[field.section] = [];
    }
    acc[field.section].push(field);
    return acc;
  }, {});

  const handleIconClick = (field) => {
    setSelectedField(field);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedField(null);
  };

  return (
    <div className="extracted-fields-viewer">
      {Object.entries(groupedFields).map(([sectionName, fields]) => (
        <SectionComponent
          key={sectionName}
          sectionName={sectionName}
          fields={fields}
          onIconClick={handleIconClick}
        />
      ))}
      {selectedField && (
        <PDFModal
          isOpen={modalOpen}
          onClose={closeModal}
          pdfUrl={URL.createObjectURL(files[0])}
          highlightBoxes={[selectedField.box]}
        />
      )}
    </div>
  );
};

export default ExtractedFieldsViewer;
