import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";

function PublicCompsForm({ pptContent, setPptContent }) {
  const removeCompany = (companyName) => {
    setPptContent((prevState) => ({
      ...prevState, // Maintain the structure of the top-level state
      public_comps: prevState.public_comps.filter(
        (comp) => comp.company_name !== companyName
      ),
    }));
  };

  const addCompany = (companyName, stockTicker) => {
    setPptContent((prevState) => ({
      ...prevState, // Maintain the structure of the top-level state
      public_comps: [
        ...prevState.public_comps,
        { company_name: companyName, stock_ticker: stockTicker },
      ],
    }));
  };

  const updateCompanyName = (oldCompanyName, newCompanyName) => {
    setPptContent((prevState) => ({
      ...prevState, // Spread the top level of prevState to maintain all other state
      public_comps: prevState.public_comps.map((comp) =>
        comp.company_name === oldCompanyName
          ? { ...comp, company_name: newCompanyName }
          : comp
      ),
    }));
  };

  const updateStockTicker = (oldStockTicker, newStockTicker) => {
    setPptContent((prevState) => ({
      ...prevState, // Spread the top level of prevState to maintain all other state
      public_comps: prevState.public_comps.map((comp) =>
        comp.stock_ticker === oldStockTicker
          ? { ...comp, stock_ticker: newStockTicker }
          : comp
      ),
    }));
  };

  console.log(pptContent);

  return (
    <div className="editable-rows-form" style={{ marginTop: "40px" }}>
      {pptContent.public_comps.map((comp) => {
        if (!["SPY", "NDAQ"].includes(comp.stock_ticker)) {
          return (
            <div key={comp.company_name} className="competitor-container">
              <InlineEdit
                key={`name-${comp.company_name}`}
                text={comp.company_name}
                onTextChange={(newName) =>
                  updateCompanyName(comp.company_name, newName)
                }
              />
              <InlineEdit
                key={`ticker-${comp.stock_ticker}`}
                text={comp.stock_ticker}
                onTextChange={(newTicker) =>
                  updateStockTicker(comp.stock_ticker, newTicker)
                }
              />
              <button
                className="competitor-add-remove-button"
                style={{ backgroundColor: "red" }}
                onClick={() => removeCompany(comp.company_name)}
              >
                -
              </button>
            </div>
          );
        }
        return null;
      })}
      <AddCompanyForm style={{ marginTop: 0 }} addCompany={addCompany} />
    </div>
  );
}

const AddCompanyForm = ({ addCompany }) => {
  const [newCompanyName, setNewCompanyName] = useState("");
  const [newStockTicker, setNewStockTicker] = useState("");

  const handleSubmit = (e) => {
    if (newCompanyName !== "") {
      e.preventDefault();
      addCompany(newCompanyName, newStockTicker);
      setNewCompanyName("");
      setNewStockTicker("");
    }
  };

  return (
    <div
      className="add-competitor-form"
      style={{ marginLeft: 0, marginBottom: 40, marginTop: 5 }}
    >
      <Form.Control
        className="add-competitor-form-input"
        style={{ width: "190.75px" }}
        size="sm"
        type="text"
        placeholder="Company Name"
        value={newCompanyName}
        onChange={(e) => setNewCompanyName(e.target.value)}
      />
      <Form.Control
        className="add-competitor-form-input"
        style={{ width: "190.75px" }}
        size="sm"
        type="text"
        placeholder="Stock Ticker"
        value={newStockTicker}
        onChange={(e) => setNewStockTicker(e.target.value)}
      />
      <Button
        size="sm"
        variant="primary"
        type="button"
        className="competitor-add-remove-button"
        onClick={handleSubmit}
        style={{ backgroundColor: "green" }}
      >
        +
      </Button>
    </div>
  );
};

const InlineEdit = ({ text, onTextChange }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(text);

  const handleBlur = () => {
    setIsEditing(false);
    onTextChange(inputValue);
  };

  if (isEditing) {
    return (
      <Form.Control
        className="vc-competitor-form-row"
        style={{ width: "190px" }}
        size="sm"
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onBlur={handleBlur}
        autoFocus
      />
    );
  } else {
    return (
      <span style={{ width: "200px" }} onClick={() => setIsEditing(true)}>
        {text}
      </span>
    );
  }
};

export default PublicCompsForm;
