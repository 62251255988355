import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import PDFViewer from "./PDFViewer";
import ReactDOM from "react-dom";

function PDFQueryForm({
  pptOptions,
  setPptOptions,
  pptContent,
  setPptContent,
  pdfUrls,
  powerPointType,
}) {
  const [selectedQueryLinkIndex, setSelectedQueryLinkIndex] = useState(-1);

  const modalBody = () => {
    const pdfUrl =
      pdfUrls[pptOptions[selectedQueryLinkIndex].box.documentIndex];
    const highlightBoxes = [pptOptions[selectedQueryLinkIndex].box];
    return (
      <div
        style={{
          backgroundColor: "#fff",
          position: "fixed",
          left: 0,
          top: 0,
          height: "100%",
          width: "100%",
          zIndex: 9999,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <button
            style={{
              backgroundColor: "#357edd",
              border: "none",
              borderRadius: "4px",
              color: "#ffffff",
              cursor: "pointer",
              width: "150px",
              marginRight: "20px",
            }}
            onClick={() => setSelectedQueryLinkIndex(-1)}
          >
            Close
          </button>
        </div>
        <PDFViewer pdfUrl={pdfUrl} highlightBoxes={highlightBoxes} />
      </div>
    );
  };

  const removeQuery = (indexToRemove) => {
    setPptContent((prevData) => {
      return prevData.filter((_, index) => index !== indexToRemove);
    });
    setPptOptions((prevData) => {
      return prevData.filter((_, index) => index !== indexToRemove);
    });
  };

  const addQuery = (query) => {
    setPptContent((prevData) => [...prevData, query]);
  };

  const updateQuery = (newQuery, index) => {
    setPptContent((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = newQuery;
      return updatedData;
    });
  };

  console.log(pptOptions);

  return (
    <div className="editable-rows-form">
      {pptContent.map((query, index) => (
        <div key={query}>
          <div className="query-container">
            <InlineEdit
              key={query}
              text={query}
              onTextChange={(newQuery) => updateQuery(newQuery, index)}
            />
            <button
              className="competitor-add-remove-button"
              style={{ backgroundColor: "red" }}
              onClick={() => removeQuery(index)}
            >
              -
            </button>
          </div>
          {/* <div>
            <span style={{ width: "600px", color: "blue" }}>
              {pptOptions[index].response}
            </span>
          </div> */}
          {pptOptions.length > index &&
            pptOptions[0].hasOwnProperty("response") &&
            (pptOptions[index].answer_found ? (
              <div style={{ width: "600px" }}>
                <span style={{ fontStyle: "italic" }}>
                  {pptOptions[index].response + " "}
                </span>
                <span
                  style={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => setSelectedQueryLinkIndex(index)}
                >
                  (view source)
                </span>
              </div>
            ) : (
              <div style={{ width: "600px" }}>
                <span style={{ fontStyle: "italic" }}>Answer not found</span>
              </div>
            ))}
        </div>
      ))}
      {selectedQueryLinkIndex !== -1 &&
        ReactDOM.createPortal(modalBody(pptContent[0].boxes), document.body)}
      {powerPointType === "pdf_parser" && <AddQueryForm addQuery={addQuery} />}
    </div>
  );
}

const AddQueryForm = ({ addQuery }) => {
  const [query, setQuery] = useState("");

  const handleSubmit = (e) => {
    if (query !== "") {
      e.preventDefault();
      addQuery(query);
      setQuery("");
    }
  };

  return (
    <div
      className="add-competitor-form"
      style={{ marginLeft: 0, marginBottom: 40, marginTop: 40 }}
    >
      <Form.Control
        className="add-query-form-input"
        size="sm"
        type="text"
        placeholder="Type query"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <Button
        size="sm"
        variant="primary"
        type="button"
        className="competitor-add-remove-button"
        onClick={handleSubmit}
        style={{ backgroundColor: "green" }}
      >
        +
      </Button>
    </div>
  );
};

const InlineEdit = ({ text, onTextChange }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(text);

  const handleBlur = () => {
    setIsEditing(false);
    onTextChange(inputValue);
  };

  if (isEditing) {
    return (
      <Form.Control
        className="add-query-form-input"
        size="sm"
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onBlur={handleBlur}
        autoFocus
      />
    );
  } else {
    return (
      <span
        style={{ width: "600px", fontWeight: "bold" }}
        onClick={() => setIsEditing(true)}
      >
        {text}
      </span>
    );
  }
};

export default PDFQueryForm;
