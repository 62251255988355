import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TopNavbar from "../navbars/TopNavbar.js";
import DemoForm from "./DemoForm";
import { getCurrentUser } from "aws-amplify/auth";
import { signOut } from "aws-amplify/auth";
import React, { useEffect } from "react";
import { sleep } from "../Utils.js";

function DemoPage() {
  useEffect(() => {
    const isUserLoggedIn = async () => {
      try {
        let user = await getCurrentUser();
        let email = user.signInDetails.loginId;
      } catch (error) {
        await signOut();
      }
    };
    isUserLoggedIn();
  }, []);

  return (
    <Container>
      <Row>
        <Col
          style={{
            position: "absolute",
            zIndex: 10,
          }}
        >
          <TopNavbar />
          {/* <SideNavbar /> */}
        </Col>
        <Col>
          <DemoForm />
        </Col>
      </Row>
    </Container>
  );
}

export default DemoPage;
