import { useEffect } from "react";
import { getCurrentUser } from "aws-amplify/auth";
import { signOut } from "aws-amplify/auth";
import { useState } from "react";
import { fetchAuthSession } from "aws-amplify/auth";
import TopNavbar from "../navbars/TopNavbar.js";
import BottomNavbar from "../navbars/BottomNavbar.js";
import LeftNavbar from "../navbars/LeftNavbar.js";
import * as JSZip from "jszip";
import { getCurrentDate } from "../Utils.js";

import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import Toast from "react-bootstrap/Toast";

import SlideViewer from "./SlideViewer";
import DeckViewer from "./DeckViewer";
import PDFQueryForm from "./PDFQueryForm";
import ExtractedFieldsViewer from "./ExtractedFieldsViewer";
import ModelAbstractorInstructions from "./ModelAbstractorInstructions";

function StudioPage() {
  const [isEnterpriseCustomer, setIsEnterpriseCustomer] = useState(null);
  const [userProducts, setUserProducts] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [zipReceived, setZipReceived] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [pptxUrl, setPptxUrl] = useState("");
  const [docxUrl, setDocxUrl] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedFileUrls, setUploadedFileUrls] = useState([]);
  const [excelBackupUrl, setExcelBackupUrl] = useState("");
  const [pptContent, setPptContent] = useState([]);
  const [pptOptions, setPptOptions] = useState([]);
  const [extraParams, setExtraParams] = useState([]);
  const [outputFileNamesMapping, setOutputFileNamesMapping] = useState({});
  const [pptFormMappingInfo, setPptFormMappingInfo] = useState({});
  const [currentSlideNumber, setCurrentSlideNumber] = useState(-1);
  const [studioFormData, setStudioFormData] = useState({
    powerPointType: "",
    companyName: "",
    industryName: "",
    crunchbaseID: "",
    googleSearchQuery: "",
    competitiveLandscapeQuery: "",
    companyOverviewDeckType: "",
    competitorsSearchType: "",
    founderNamesString: "",
    customerOrgID: "",
    leadershipTeamUrl: "",
    stockTicker: "",
    companyUrl: "",
    pdfType: "",
    cashAmount: "",
    actualTimePeriod: "",
    equityFundingAmount: "",
    debtFundingAmount: "",
    fundingTimePeriod: "",
  });

  const handleStudioFormChange = (updatedFields) => {
    setStudioFormData((prevState) => ({
      ...prevState,
      ...updatedFields,
    }));
    // setZipReceived(false);
    // setPdfUrl("");
    // setPptxUrl("");
    // setDocxUrl("");
    // setExcelBackupUrl("");
    // setPptContent([]);
    // setPptFormMappingInfo({});
    // setCurrentSlideNumber(-1);
  };

  useEffect(() => {
    const isUserLoggedIn = async () => {
      try {
        let user = await getCurrentUser();
        let email = user.signInDetails.loginId;
        setUserEmail(email);
      } catch (error) {
        await signOut();
      }
    };
    isUserLoggedIn();
    const getIsEnterpriseUser = async () => {
      try {
        const { accessToken } = (await fetchAuthSession()).tokens ?? {};
        const isEnterpriseUserEndpoint = process.env.REACT_APP_API_URL.concat(
          "/is_enterprise_user"
        );
        const response = await fetch(isEnterpriseUserEndpoint, {
          method: "GET",
          headers: {
            Authorization: accessToken,
            "content-type": "application/json",
          },
        });
        const responseJson = await response.json();
        setIsEnterpriseCustomer(responseJson.is_enterprise_user);
        if (responseJson.is_enterprise_user) {
          setUserProducts(responseJson.products);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getIsEnterpriseUser();
  }, []);

  const getStudioAPIEndpoint = () => {
    const params = { ...studioFormData };

    // Modify specific fields in params
    params.email = userEmail;
    params.date = getCurrentDate();
    params.companyName = params.companyName.replace("&", "and");
    params.companyUrl = getShortenedUrl(params.companyUrl);
    params.customerOrgID = params.customerOrgID
      ? parseInt(params.customerOrgID, 10)
      : null;

    const queryParams = Object.keys(params)
      .filter((key) => params[key] !== null && params[key] !== "")
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");

    return `${process.env.REACT_APP_API_URL}/generate_powerpoint_studio?${queryParams}`;
  };

  const getShortenedUrl = (url) => {
    if (!url) return null;
    const urlChunks = url.split(".");
    return `${urlChunks[urlChunks.length - 2]}.${
      urlChunks[urlChunks.length - 1]
    }`;
  };

  async function downloadResponseZip(blob) {
    const zip = new JSZip();
    const zipContent = await zip.loadAsync(blob);

    const handleBlobFile = async (filename, setter) => {
      const fileBlob = await zip.file(filename).async("blob");
      const fileUrl = window.URL.createObjectURL(new Blob([fileBlob]));
      setter(fileUrl);
    };

    const handleStringFile = async (filename, setter) => {
      const fileText = await zip.file(filename).async("string");
      setter(JSON.parse(fileText));
    };

    for (const filename of Object.keys(zipContent.files)) {
      if (filename.endsWith(".pdf")) {
        await handleBlobFile(filename, setPdfUrl);
      } else if (filename.endsWith(".pptx")) {
        await handleBlobFile(filename, setPptxUrl);
      } else if (filename.endsWith(".docx")) {
        await handleBlobFile(filename, setDocxUrl);
      } else if (filename.endsWith("ppt_content.txt")) {
        await handleStringFile(filename, setPptContent);
      } else if (filename.endsWith("ppt_options.txt")) {
        await handleStringFile(filename, setPptOptions);
      } else if (filename.endsWith("output_file_names_mapping.txt")) {
        await handleStringFile(filename, setOutputFileNamesMapping);
      } else if (filename.endsWith("ppt_form_mapping_info.txt")) {
        await handleStringFile(filename, setPptFormMappingInfo);
      } else if (filename.endsWith("excel_backup.xlsx")) {
        await handleBlobFile(filename, setExcelBackupUrl);
      }
    }

    setZipReceived(true);
    setCurrentSlideNumber(-1);
  }

  const topNavbarOnly = (
    <Container>
      <Row>
        <Col
          style={{
            position: "absolute",
            zIndex: 15,
          }}
        >
          <TopNavbar />
        </Col>
      </Row>
    </Container>
  );

  const enterpriseOnlyMessage = (
    <Container>
      <Row>
        <Col
          style={{
            position: "absolute",
            zIndex: 15,
          }}
        >
          <TopNavbar />
        </Col>
      </Row>
      <Row className="center_horizontal">
        <Toast
          position="top-center"
          bg="warning"
          style={{ position: "absolute", zIndex: 1, marginTop: "300px" }}
        >
          <Toast.Body style={{ color: "black" }}>
            Lucite Studio is only available for enterprise partners. Please
            reach out to info@lucite.ai for more information.
          </Toast.Body>
        </Toast>
      </Row>
    </Container>
  );

  const fullPage = (
    <Container>
      <Row>
        <Col
          style={{
            position: "absolute",
            zIndex: 15,
          }}
        >
          <TopNavbar />
          <LeftNavbar
            studioFormData={studioFormData}
            getStudioAPIEndpoint={getStudioAPIEndpoint}
            handleStudioFormChange={handleStudioFormChange}
            downloadResponseZip={downloadResponseZip}
            pptContent={pptContent}
            setPptContent={setPptContent}
            pptOptions={pptOptions}
            setPptOptions={setPptOptions}
            pdfUrl={pdfUrl}
            setUploadedFileUrls={setUploadedFileUrls}
            userProducts={userProducts}
            extraParams={extraParams}
            setExtraParams={setExtraParams}
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
          />
        </Col>
        <Col>
          <Container className="center-powerpoint-viewing-space">
            {zipReceived &&
              currentSlideNumber === -1 &&
              ![
                "pdf_parser",
                "real_estate_parser",
                "model_parser",
                "lease_parser",
              ].includes(studioFormData.powerPointType) && (
                <DeckViewer
                  pdfUrl={pdfUrl}
                  currentSlideNumber={currentSlideNumber}
                  setCurrentSlideNumber={setCurrentSlideNumber}
                />
              )}
            {zipReceived && currentSlideNumber !== -1 && (
              <SlideViewer
                pdfUrl={pdfUrl}
                pptContent={pptContent}
                setPptContent={setPptContent}
                pptOptions={pptOptions}
                setPptOptions={setPptOptions}
                studioFormData={studioFormData}
                currentSlideNumber={currentSlideNumber}
                setCurrentSlideNumber={setCurrentSlideNumber}
                pptFormMappingInfo={pptFormMappingInfo}
              />
            )}
            {["pdf_parser", "company_memo", "lease_parser"].includes(
              studioFormData.powerPointType
            ) && (
              <PDFQueryForm
                pptOptions={pptOptions}
                setPptOptions={setPptOptions}
                pptContent={pptContent}
                setPptContent={setPptContent}
                pdfUrls={uploadedFileUrls}
                powerPointType={studioFormData.powerPointType}
              />
            )}
            {["real_estate_parser"].includes(studioFormData.powerPointType) && (
              <ExtractedFieldsViewer
                extractedFields={pptOptions}
                files={uploadedFiles}
              />
            )}
            {["model_parser"].includes(studioFormData.powerPointType) && (
              <ModelAbstractorInstructions pptContent={pptContent} />
            )}
          </Container>
        </Col>
        <Col
          style={{
            position: "absolute",
            zIndex: 10,
          }}
        >
          <BottomNavbar
            pdfUrl={pdfUrl}
            pptxUrl={pptxUrl}
            docxUrl={docxUrl}
            excelBackupUrl={excelBackupUrl}
            studioFormData={studioFormData}
            outputFileNamesMapping={outputFileNamesMapping}
          />
        </Col>
      </Row>
    </Container>
  );

  if (isEnterpriseCustomer === null) {
    return topNavbarOnly;
  } else if (!isEnterpriseCustomer) {
    return enterpriseOnlyMessage;
  } else {
    return fullPage;
  }
}

export default StudioPage;
