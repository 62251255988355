import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import StudioForm from "../studio/StudioForm";
import React from "react";

function LeftNavbar(props) {
  return (
    <Navbar expand="lg" variant="light" className="bg-light left-navbar">
      <Container>
        <div
          style={{
            flex_direction: "column",
            height: "95vh",
            overflowY: "auto",
          }}
        >
          <StudioForm
            studioFormData={props.studioFormData}
            getStudioAPIEndpoint={props.getStudioAPIEndpoint}
            handleStudioFormChange={props.handleStudioFormChange}
            downloadResponseZip={props.downloadResponseZip}
            pptContent={props.pptContent}
            pptOptions={props.pptOptions}
            setPptOptions={props.setPptOptions}
            pdfUrl={props.pdfUrl}
            setUploadedFileUrls={props.setUploadedFileUrls}
            userProducts={props.userProducts}
            extraParams={props.extraParams}
            setExtraParams={props.setExtraParams}
            uploadedFiles={props.uploadedFiles}
            setUploadedFiles={props.setUploadedFiles}
          />
        </div>
      </Container>
    </Navbar>
  );
}

export default LeftNavbar;
